import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  MenuItem,
  Select,
  Popover,
  Button,
  Typography,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight, MoreVert } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';

type SortOrder = 'asc' | 'desc';

export default function CustomTable({
  headers,
  rawData,
  renderRow,
  renderRowMobile,
  rowsPerPage = 10,
  initialSortConfig = { column: null, order: 'asc' },
}: {
  headers: { display: string; key: string }[];
  rawData: any[];
  renderRow: (row: any, index: number) => JSX.Element;
  renderRowMobile?: (row: any, index: number) => JSX.Element;
  rowsPerPage?: number;
  initialSortConfig?: { column: string | null; order: SortOrder };
}) {
  // Global sorting & filtering configuration
  const [sortConfig, setSortConfig] = useState<{
    column: string | null;
    order: SortOrder;
  }>(initialSortConfig);
  const [filterConfig, setFilterConfig] = useState<{
    [key: string]: string | { operator: 'lt' | 'gt'; value: string };
  }>({});
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState(rawData);

  // State for the desktop filter/sort popover
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null);
  const [filterColumn, setFilterColumn] = useState<string | null>(null);
  // Temporary filter value (either string or numeric object)
  const [tempFilter, setTempFilter] = useState<any>(null);
  // Temporary sort for the column ("none" | "asc" | "desc")
  const [tempSort, setTempSort] = useState<'none' | 'asc' | 'desc'>('none');

  // Mobile filtering state
  const [openMobileFilter, setOpenMobileFilter] = useState(false);
  const [mobileFilterColumn, setMobileFilterColumn] = useState<string>('');
  const [mobileTempFilter, setMobileTempFilter] = useState<any>(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();

  // Handle sorting on the table header (desktop)
  const handleSort = (column: string) => {
    const isAsc = sortConfig.column === column && sortConfig.order === 'asc';
    const order: SortOrder = isAsc ? 'desc' : 'asc';
    setSortConfig({ column, order });
  };

  // Desktop: Open the popover for filter/sort for a given column.
  const handleFilterIconClick = (event: React.MouseEvent<HTMLElement>, columnKey: string) => {
    setFilterAnchorEl(event.currentTarget);
    setFilterColumn(columnKey);
    const isNumeric = rawData.length > 0 && typeof rawData[0][columnKey] === 'number';
    const currentFilter = filterConfig[columnKey];
    if (isNumeric) {
      if (typeof currentFilter === 'object') {
        setTempFilter({ operator: currentFilter.operator, value: currentFilter.value });
      } else {
        setTempFilter({ operator: 'lt', value: '' });
      }
    } else if (typeof currentFilter === 'string') {
        setTempFilter(currentFilter);
      } else {
        setTempFilter('');
      }
    // Set temporary sort: if this column is already sorted, prefill with its order; else "none"
    if (sortConfig.column === columnKey) {
      setTempSort(sortConfig.order);
    } else {
      setTempSort('none');
    }
  };

  const handleFilterClose = () => {
    setFilterAnchorEl(null);
    setFilterColumn(null);
    setTempFilter(null);
    setTempSort('none');
  };

  // Desktop: Apply both the filter and sort settings from the popover.
  const handleFilterApply = () => {
    if (filterColumn) {
      setFilterConfig((prev) => ({
        ...prev,
        [filterColumn]: tempFilter,
      }));
      if (tempSort !== 'none') {
        setSortConfig({ column: filterColumn, order: tempSort });
      } else if (sortConfig.column === filterColumn) {
        setSortConfig({ column: null, order: 'asc' });
      }
    }
    handleFilterClose();
    setPage(0);
  };

  // Desktop: Clear filtering and sorting for the given column.
  const handleFilterClear = () => {
    if (filterColumn) {
      setFilterConfig((prev) => ({
        ...prev,
        [filterColumn]: '',
      }));
      if (sortConfig.column === filterColumn) {
        setSortConfig({ column: null, order: 'asc' });
      }
    }
    handleFilterClose();
    setPage(0);
  };

  // Re-filter and re-sort data when rawData, filterConfig, or sortConfig change.
  useEffect(() => {
    const filteredData = rawData.filter((row) => headers.every((header) => {
        const filterVal = filterConfig[header.key];
        if (!filterVal || (typeof filterVal === 'string' && filterVal === '')) return true;
        const cellValue = row[header.key];
        // Numeric filtering: expect an object with { operator, value }
        if (typeof cellValue === 'number') {
          const numericFilter = filterVal as { operator: 'lt' | 'gt'; value: string };
          if (numericFilter.value === '') return true;
          const filterNumber = Number(numericFilter.value);
          if (Number.isNaN(filterNumber)) return true;
          return numericFilter.operator === 'lt'
            ? cellValue < filterNumber
            : cellValue > filterNumber;
        }
        // String filtering: case-insensitive substring match.
        return String(cellValue)
          .toLowerCase()
          .includes((filterVal as string).toLowerCase());
      }));

    // if (sortConfig.column) {
    //   filteredData.sort((a, b) => {
    //     const aValue = a[sortConfig.column as string];
    //     const bValue = b[sortConfig.column as string];
    //
    //     if (aValue > bValue) return sortConfig.order === 'asc' ? -1 : 1;
    //     if (aValue < bValue) return sortConfig.order === 'asc' ? 1 : -1;
    //     return 0;
    //   });
    // }
    if (sortConfig.column) {
      filteredData.sort((a, b) => {
        const aValue = a[sortConfig.column as string];
        const bValue = b[sortConfig.column as string];

        if (aValue < bValue) return sortConfig.order === 'asc' ? -1 : 1;
        if (aValue > bValue) return sortConfig.order === 'asc' ? 1 : -1;
        return 0;
      });
    }
    setSortedData(filteredData);
  }, [rawData, filterConfig, sortConfig, headers]);

  const paginatedData = sortedData.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  const renderPagination = () => {
    const totalPages = Math.ceil(sortedData.length / rowsPerPage);
    const pagesToDisplay = [];

    for (let i = 0; i < totalPages; i += 1) {
      if (i < 3 || i === totalPages - 1 || Math.abs(i - page) <= 1) {
        pagesToDisplay.push(i);
      } else if (i === 3 && page > 4) {
        pagesToDisplay.push('...');
      } else if (i > page && i === totalPages - 2 && page < totalPages - 3) {
        pagesToDisplay.push('...');
      }
    }

    return (
      <Box display="flex" justifyContent="center" alignItems="center">
        <IconButton onClick={() => setPage((prev) => Math.max(0, prev - 1))} disabled={page === 0}>
          <KeyboardArrowLeft />
        </IconButton>
        {pagesToDisplay.map((p, index) => (
          <Box
            key={index}
            mx={0.5}
            p={1}
            onClick={() => typeof p === 'number' && setPage(p)}
            style={{
              cursor: typeof p === 'number' ? 'pointer' : 'default',
              fontWeight: page === p ? 'bold' : 'normal',
            }}
          >
            {typeof p === 'number' ? p + 1 : p}
          </Box>
        ))}
        <IconButton
          onClick={() => setPage((prev) => Math.min(prev + 1, totalPages - 1))}
          disabled={page === totalPages - 1}
        >
          <KeyboardArrowRight />
        </IconButton>
      </Box>
    );
  };

  // Desktop table: includes applied filter chips and a popover for per‑column filtering/sorting.
  const renderDesktopTable = () => (
    <TableContainer
      component={Paper}
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '1000px',
      }}
    >
      {/* Results Count and Applied filters as chips */}
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Results: {sortedData.length}
        </Typography>
        {Object.entries(filterConfig).map(([key, value]) => {
          if (!value || (typeof value === 'string' && value === '')) return null;
          const header = headers.find((h) => h.key === key);
          if (!header) return null;
          let label = `${header.display  }: `;
          if (typeof value === 'string') {
            label += value;
          } else if (typeof value === 'object') {
            const opLabel = value.operator === 'lt' ? 'Less than' : 'Greater than';
            label += `${opLabel  } ${  value.value}`;
          }
          return (
            <Chip
              key={key}
              label={label}
              onDelete={() =>
                setFilterConfig((prev) => ({
                  ...prev,
                  [key]: '',
                }))
              }
              variant="soft"
              sx={{ mr: 1, mb: 1 }}
            />
          );
        })}
      </Box>
      <Table style={{ flexGrow: 1 }}>
        <TableHead>
          <TableRow>
            {headers.map((header: any, index: number) => (
              <TableCell
                key={header.key}
                sx={{
                  borderTopLeftRadius: index === 0 ? '16px' : 0,
                  borderTopRightRadius: index === headers.length - 1 ? '16px' : 0,
                }}
              >
                {header.display &&
                <Box display="flex" alignItems="center" justifyContent="center">
                  <TableSortLabel
                    active={sortConfig.column === header.key}
                    direction={sortConfig.column === header.key ? sortConfig.order : 'asc'}
                    onClick={() => handleSort(header.key)}
                  >
                    {header.display}
                  </TableSortLabel>
                  <IconButton size="small" onClick={(e) => handleFilterIconClick(e, header.key)}>
                    <MoreVert fontSize="small" />
                  </IconButton>
                </Box>
                  }
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedData.map((row: any, index: number) => renderRow(row, index))}
          {paginatedData.length < rowsPerPage &&
            [...Array(rowsPerPage - paginatedData.length)].map((_, index) => (
              <TableRow key={`empty-row-${index}`} style={{ height: 53 }}>
                <TableCell colSpan={headers.length} />
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box sx={{ mt: 'auto', py: 2, backgroundColor: 'white' }}>{renderPagination()}</Box>
      {/* Desktop Popover for filtering and sorting */}
      <Popover
        open={Boolean(filterAnchorEl)}
        anchorEl={filterAnchorEl}
        onClose={handleFilterClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        {filterColumn && tempFilter !== null && (
          <Box p={2} display="flex" flexDirection="column" gap={2} minWidth={220}>
            {/* Sorting Section */}
            <Box display="flex" flexDirection="column" gap={1}>
              <Typography variant="subtitle1">Sort</Typography>
              <Select
                value={tempSort}
                onChange={(e) => setTempSort(e.target.value as 'none' | 'asc' | 'desc')}
              >
                <MenuItem value="none">None</MenuItem>
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
              </Select>
            </Box>
            {/* Filtering Section */}
            {rawData.length > 0 && typeof rawData[0][filterColumn] === 'number' ? (
              <>
                <Typography variant="subtitle1">Filter</Typography>
                <Select
                  value={tempFilter.operator}
                  onChange={(e) =>
                    setTempFilter({
                      ...tempFilter,
                      operator: e.target.value as 'lt' | 'gt',
                    })
                  }
                >
                  <MenuItem value="lt">Less than</MenuItem>
                  <MenuItem value="gt">Greater than</MenuItem>
                </Select>
                <TextField
                  variant="outlined"
                  label="Value"
                  type="number"
                  value={tempFilter.value}
                  onChange={(e) => setTempFilter({ ...tempFilter, value: e.target.value })}
                />
              </>
            ) : (
              <>
                <Typography variant="subtitle1">Filter</Typography>
                <TextField
                  variant="outlined"
                  label="Filter"
                  value={tempFilter}
                  onChange={(e) => setTempFilter(e.target.value)}
                />
              </>
            )}
            <Box display="flex" justifyContent="flex-end" gap={1}>
              <Button onClick={handleFilterClear} color="inherit">
                Clear
              </Button>
              <Button onClick={handleFilterApply} variant="contained" color="primary">
                Apply
              </Button>
            </Box>
          </Box>
        )}
      </Popover>
    </TableContainer>
  );

  // Mobile Table UI: includes sorting select, a "Filter" button to open a dialog, and applied filter chips with results count.
  const renderMobileTable = () => (
    <Stack direction="column" spacing={1} sx={{ p: 2 }}>
      {/* Sorting Select */}
      <InputLabel>Sort by</InputLabel>
      <Select
        value={sortConfig.column || ''}
        onChange={(e) => handleSort(e.target.value as string)}
        fullWidth
      >
        {headers.map(
          (header) =>
            header.display && (
              <MenuItem key={header.key} value={header.key}>
                {header.display}
              </MenuItem>
            )
        )}
      </Select>
      {/* Mobile Filter Button */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1">Filters:</Typography>
        <Button
          variant="outlined"
          onClick={() => {
            setOpenMobileFilter(true);
            // Reset temporary mobile filter state when opening
            setMobileFilterColumn('');
            setMobileTempFilter(null);
          }}
        >
          Filter
        </Button>
      </Box>
      {/* Results Count and Applied filters as chips */}
      <Box>
        <Typography variant="subtitle1" sx={{ mb: 1 }}>
          Results: {sortedData.length}
        </Typography>
        {Object.entries(filterConfig).map(([key, value]) => {
          if (!value || (typeof value === 'string' && value === '')) return null;
          const header = headers.find((h) => h.key === key);
          if (!header) return null;
          let label = `${header.display  }: `;
          if (typeof value === 'string') {
            label += value;
          } else if (typeof value === 'object') {
            const opLabel = value.operator === 'lt' ? 'Less than' : 'Greater than';
            label += `${opLabel  } ${  value.value}`;
          }
          return (
            <Chip
              key={key}
              label={label}
              onDelete={() =>
                setFilterConfig((prev) => ({
                  ...prev,
                  [key]: '',
                }))
              }
              variant="soft"
              sx={{ mr: 1, mb: 1 }}
            />
          );
        })}
      </Box>
      {/* Mobile rows */}
      <Box>
        {paginatedData.map((row: any, idx: number) =>
          renderRowMobile ? renderRowMobile(row, idx) : renderRow(row, idx)
        )}
        {renderPagination()}
      </Box>
      {/* Mobile Filter Dialog */}
      <Dialog open={openMobileFilter} onClose={() => setOpenMobileFilter(false)} fullWidth>
        <DialogTitle>Filter</DialogTitle>
        <DialogContent>
          <Select
            value={mobileFilterColumn}
            onChange={(e) => {
              const col = e.target.value as string;
              setMobileFilterColumn(col);
              // Initialize temporary value for the selected column based on type.
              const isNumeric = rawData.length > 0 && typeof rawData[0][col] === 'number';
              if (isNumeric) {
                setMobileTempFilter({ operator: 'lt', value: '' });
              } else {
                setMobileTempFilter('');
              }
            }}
            displayEmpty
            fullWidth
          >
            <MenuItem value="">Select Column</MenuItem>
            {headers.map(
              (header) =>
                header.display && (
                  <MenuItem key={header.key} value={header.key}>
                    {header.display}
                  </MenuItem>
                )
            )}
          </Select>
          {mobileFilterColumn && mobileTempFilter !== null && (
            <>
              {rawData.length > 0 && typeof rawData[0][mobileFilterColumn] === 'number' ? (
                <>
                  <Box mt={2}>
                    <Typography variant="subtitle2">Operator</Typography>
                    <Select
                      value={mobileTempFilter.operator}
                      onChange={(e) =>
                        setMobileTempFilter({
                          ...mobileTempFilter,
                          operator: e.target.value as 'lt' | 'gt',
                        })
                      }
                      fullWidth
                    >
                      <MenuItem value="lt">Less than</MenuItem>
                      <MenuItem value="gt">Greater than</MenuItem>
                    </Select>
                  </Box>
                  <Box mt={2}>
                    <TextField
                      label="Value"
                      type="number"
                      fullWidth
                      value={mobileTempFilter.value}
                      onChange={(e) =>
                        setMobileTempFilter({ ...mobileTempFilter, value: e.target.value })
                      }
                    />
                  </Box>
                </>
              ) : (
                <Box mt={2}>
                  <TextField
                    label="Filter"
                    fullWidth
                    value={mobileTempFilter}
                    onChange={(e) => setMobileTempFilter(e.target.value)}
                  />
                </Box>
              )}
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMobileFilter(false)} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={() => {
              if (mobileFilterColumn) {
                setFilterConfig((prev) => ({
                  ...prev,
                  [mobileFilterColumn]: mobileTempFilter,
                }));
              }
              setOpenMobileFilter(false);
              setPage(0);
            }}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );

  if (rawData.length === 0) {
    return (
      <Box>
        <Typography variant="h6" align="center">
          No data available
        </Typography>
      </Box>
    );
  }

  return <Box  id="company-table">{isMobile ? renderMobileTable() : renderDesktopTable()}</Box>;
}
