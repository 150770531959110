import React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';

import Iconify from '../iconify';
import AiProgressbar from '../utility_components/ai-progressbar';
import Label from '../label';
import { useAuthContext } from '../../auth/hooks';
import { BecomePremiumToAccessDialog } from '../utility_components/become-premium-to-access-dialog';
import PremiumOnlyLabel from '../premium-only-label/premium-only-label';

interface CompanyDocumentsProps {
  aiAnalysis: AIBusinessAnalysisType;
}

interface AIBusinessAnalysisType {
  business_assessment: {
    business_model_explanation: string;
    pros: string[];
    cons: string[];
  };
  operational_metrics: {
    metrics: {
      metric_name: string;
      metric_performance_and_insight: string;
      verdict: 'improved' | 'declined' | 'same';
    }[];
  };
}

export default function CompanyBusinessAiAnalysis({ aiAnalysis }: CompanyDocumentsProps) {
  const { user, authenticated } = useAuthContext();
  const [becomePremiumOpen, setBecomePremiumOpen] = React.useState(false);

  if (!aiAnalysis.business_assessment) {
    return <AiProgressbar />;
  }

  const isPremium = user?.subscription && user.subscription?.is_active;
  const blurred = !authenticated || !isPremium;
  const blurStyle = { filter: 'blur(5px)', cursor: 'pointer' };

  const getLabelElement = (verdict: string) => {
    switch (verdict) {
      case 'improved':
        return <Label color="success"> Improved </Label>;
      case 'declined':
        return <Label color="error"> Declined </Label>;
      default:
        return <Label color="info"> Same </Label>;
    }
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={0.8} alignItems="center">
              <Iconify width={24} icon="icons8:idea" sx={{ color: 'success.main' }} />
              <Typography variant="h6" sx={{ color: 'text.primary' }}>
                Business Model
              </Typography>
            </Stack>

            <Typography
              variant="body2"
              sx={{
                color: 'text.primary',
              }}
            >
              <ReactMarkdown>
                {aiAnalysis?.business_assessment.business_model_explanation}
              </ReactMarkdown>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Stack
          direction="column"
          spacing={0.5}
          sx={{ mt: 1 }}
          onClick={blurred ? () => setBecomePremiumOpen(true) : undefined}
        >
          <Stack direction="row" spacing={0.8} alignItems="center" sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Strengths
            </Typography>
            <PremiumOnlyLabel />
          </Stack>
          {aiAnalysis?.business_assessment.pros.map((text, index) => (
            <Box key={index} sx={blurred ? blurStyle : {}}>
              <Stack direction="row" spacing={0.8}>
                <Iconify
                  width={24}
                  icon="icon-park-twotone:success"
                  sx={{ color: 'success.main' }}
                />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {text}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
        <Stack
          direction="column"
          spacing={0.5}
          sx={{ mt: 1 }}
          onClick={blurred ? () => setBecomePremiumOpen(true) : undefined}
        >
          <Stack direction="row" spacing={0.8} alignItems="center" sx={{ mt: 3 }}>
            <Typography variant="h6" sx={{ color: 'text.primary' }}>
              Concerns
            </Typography>
            <PremiumOnlyLabel />
          </Stack>
          {aiAnalysis?.business_assessment.cons.map((text, index) => (
            <Box key={index} sx={blurred ? blurStyle : {}}>
              <Stack direction="row" spacing={0.8}>
                <Iconify width={24} icon="ic:twotone-error" sx={{ color: 'error.main' }} />
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  {text}
                </Typography>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <Stack direction="row" spacing={0.8} alignItems="center" sx={{ mt: 3 }}>
          <Iconify width={24} icon="icons8:idea" sx={{ color: 'success.main' }} />
          <Typography variant="h6" sx={{ color: 'text.primary' }}>
            Operational Insights
          </Typography>
          <PremiumOnlyLabel />
        </Stack>
        <Stack
          direction="column"
          sx={{ mt: 2 }}
          onClick={blurred ? () => setBecomePremiumOpen(true) : undefined}
        >
          {aiAnalysis?.operational_metrics.metrics.map((metric, index) => (
            <Box key={`metric-${index}`}>
              <Stack direction="row" spacing={0.8}>
                {getLabelElement(metric.verdict)}
                <Typography variant="body2" fontWeight="bold">
                  {metric.metric_name}
                </Typography>
              </Stack>
              <Typography variant="body2" sx={blurred ? blurStyle : {}}>
                <ReactMarkdown>{metric.metric_performance_and_insight}</ReactMarkdown>
              </Typography>
            </Box>
          ))}
        </Stack>
      </Grid>
      <BecomePremiumToAccessDialog
        ticker={undefined}
        open={becomePremiumOpen}
        onClose={() => setBecomePremiumOpen(false)}
        premiumOnly
      />
    </Grid>
  );
}
