import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import LinearProgress from '@mui/material/LinearProgress';
import { FiberNew } from '@mui/icons-material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { debounce } from 'lodash';

import ReactGA from 'react-ga4';

import { fNumber, fPercent, fShortenNumber } from 'src/utils/format-number';
import axios, { endpoints } from '../../utils/axios';
import { AIAnalysisTypesEnum, CompanyDocumentsType, ScoredCompany2 } from './types';
import PriceChart from './price-chart';
import CompanyFinancials from './financials';
import QuickRatios from './quick-ratios';
import CompanyLogo, { defaultGrowthScore } from './constants';
import CompanyPublicDocuments from './company-public-documents';
import AIAnalysis from './a-i-analysis';
import CompanyValuation from './company-valuation';
import {
  getCurrencySymbol,
  getQuickNumbersForCompany,
  getUserType,
  scrollToElement,
  UserTypes,
} from './utils';
import CompanyBusiness from './company-business';
import { LoginToAccess } from '../utility_components/login-to-access';
import { BecomePremiumAccess } from '../utility_components/become-premium-access';
import CompanyGrowth from './company-growth';
import CompanyFinancialStrength from './company-financial-strength';
import NewsAnalysis from './news_analysis';
import Iconify from '../iconify';
import { gaTimingEvent } from '../../utils/ga';
import { useAuthContext } from '../../auth/hooks';
import {
  addToVisitedCompanies,
  getVisitedCompanies,
  setVisitedCompaniesToTwo,
} from '../../utils/stogare-utils';
import OneMinAnalysis from './one_min_analysis';
import AnnualReportAnalysisLoginGuard from '../documents_analysis/annual_report_analysis_login_guard';
import EarningsCallAnalysis from '../earnings_call_analysis/earnings_call_analysis';
import HelpIcon from '../utility_components/help-icon';
import GeneratedOn from '../utility_components/generated-on';
import {
  dividendYieldExplanation,
  evEbitdaExplanation,
  pbExplanation,
  peExplanation,
  pegExplanation,
  pfcfExplanation,
  psExplanation,
} from '../../common/texts';
import CompanyNavigation from './company-navigation';
import CompanyInsiderTrading from '../insider-trading/company-insider-trading';
import ChatTriggerButton from '../chat/chat-trigger-button/chat-trigger-button';
import { CreditsProvider } from '../../providers/credits-provider';
import LoginRegisterDialog from '../../layouts/credits/login-register-dialog';
import Label from '../label';

export default function CompanyDetail() {
  ReactGA.send({ hitType: 'pageview', page: window.location.pathname, title: 'Company Detail' });
  const { authenticated, user, initialize } = useAuthContext();

  const { ticker } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  ReactGA.event('CompanyView', {
    category: 'Company',
    action: 'View details for company',
    label: ticker,
  });
  const defaultCompany = {
    company_name: '',
    ticker: '',
    sector: '',
    industry: '',
    market_cap: 0,
    price: 0,
    description: '',
    image_url: '',
    score: 0,
    score_data: {
      price_data: {
        score: 0,
        price_to_earnings: 0,
        price_to_book: 0,
        price_to_free_cashflow: 0,
        ev_to_ebitda: 0,
        peg: 0,
      },
      solvency_data: {
        score: 0,
        current_ratio: 0,
        quick_ratio: 0,
        debt_to_equity: 0,
        debt_to_assets: 0,
        interest_coverage_ratio: 0,
        piotroski_score: 0,
      },
      efficiency_data: {
        score: 0,
        return_on_capital_employed: 0,
        return_on_equity: 0,
        return_on_assets: 0,
        gross_profit_margin: 0,
        net_profit_margin: 0,
        asset_turnover: 0,
      },
      growth_data: {
        score: 0,
        revenue_1y_expected_change: 0,
        ebitda_1y_expected_change: 0,
        eps_1y_expected_change: 0,
        revenue_5y_actual_change: 0,
        ebitda_5y_actual_change: 0,
        eps_5y_actual_change: 0,
        revenue_growth_consistency: 0,
        ebitda_growth_consistency: 0,
        eps_growth_consistency: 0,
      },
    },
    score_updated: '',
    prices: [],
    country: '',
    currency: '',
    website: '',
    full_time_employees: 0,
    ceo: '',
    quick_numbers: {
      valuation: {
        price_earnings_ratio: 0,
        price_to_book_ratio: 0,
        price_to_sales_ratio: 0,
        price_to_free_cash_flows_ratio: 0,
        price_to_operating_cash_flows_ratio: 0,
        ev_to_ebitda: 0,
        price_to_earnings_growth_ratio: 0,
      },
      solvency: {
        debt_to_equity_ratio: 0,
        interest_coverage_ratio: 0,
        cashflow_to_debt_ratio: 0,
        debt_ratio: 0,
        long_term_debt_to_capitalization_ratio: 0,
        current_ratio: 0,
        cash_ratio: 0,
      },
      efficiency: {
        return_on_equity_ratio: 0,
        return_on_capital_employed: 0,
        gross_profit_margin: 0,
        net_profit_margin: 0,
        asset_turnover_ratio: 0,
        inventory_turnover_ratio: 0,
        receivables_turnover_ratio: 0,
      },
      growth: {
        revenue_growth: 0,
        net_income_growth: 0,
        operating_cashflow_growth: 0,
        five_y_revenue_growth: 0,
        five_y_net_income_growth: 0,
        expected_revenue_growth_quarter: 0,
        expected_revenue_growth_year: 0,
      },
      dividend_yield_percentage: 0,
    },
  };

  const [company, setCompany] = useState<ScoredCompany2>(defaultCompany as ScoredCompany2);
  const [documents, setCompanyDocuments] = useState<CompanyDocumentsType | null>(null);
  const [companyContext, setCompanyContext] = useState<any | undefined>(undefined);
  const [companyContextLoading, setCompanyContextLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [aiAnalysesReady, setAiAnalysesReady] = useState({
    news: false,
    // valuation: false, // remove this while AskNews access is resolved
    business: false,
    growth: false,
    financialStrength: false,
    earningsCall: false,
    quick_numbers: false,
    annual_financials: false,
  });
  const onAnalysisReady = useCallback(
    (key: string) => {
      setAiAnalysesReady((prev) => {
        const newReady = { ...prev };
        // @ts-ignore
        newReady[key] = true;
        return newReady;
      });
    },
    [setAiAnalysesReady]
  );

  const [oneMinAnalysis, setOneMinAnalysis] = useState<any>(null);
  const [oneMinAnalysisLoading, setOneMinAnalysisLoading] = useState(true);
  const [oneMinAnalysisLatestLoading, setOneMinAnalysisLatestLoading] = useState(false);
  const [oneMinAnalysisIsLatest, setOneMinAnalysisIsLatest] = useState(true);
  const [oneMinAnalysisDatetimeGenerated, setOneMinAnalysisDatetimeGenerated] =
    useState<string>('');
  const [userType, setUserType] = useState<UserTypes | null>(getUserType(user, ticker));

  useEffect(() => {
    const getOneMinAnalysis = async () => {
      const handleResponse = (response: any) => {
        setOneMinAnalysis(response.data.answer);
        setOneMinAnalysisDatetimeGenerated(response.data.datetime_generated);
      };

      try {
        if (oneMinAnalysis === null) {
          const result = await axios.post<any>(
            `${endpoints.ai_analysis}/${ticker}?analysis_type=one_min`
          );
          handleResponse(result);
          console.log('response:', result);
          setOneMinAnalysisIsLatest(result.data.is_latest);
          setOneMinAnalysisLoading(false);
        }

        if (
          !oneMinAnalysisIsLatest &&
          Object.values(aiAnalysesReady).every((value) => value) &&
          !oneMinAnalysisLatestLoading
        ) {
          setOneMinAnalysisLatestLoading(true);
          const latestResult = await axios.post<any>(
            `${endpoints.ai_analysis}/${ticker}?analysis_type=one_min&return_latest=true`
          );
          handleResponse(latestResult);
          setOneMinAnalysisIsLatest(true);
        }
      } catch (error) {
        console.error('Error fetching one min analysis:', error);
      } finally {
        setOneMinAnalysisLoading(false);
        setOneMinAnalysisLatestLoading(false);
      }
    };
    // if all analyses are ready, set visited companies to 2
    const userTypeUpdated = getUserType(user, ticker);
    const shouldLoad =
      authenticated &&
      (userTypeUpdated === UserTypes.PremiumWithCredits ||
        userTypeUpdated === UserTypes.FreeWithCredits);

    if (shouldLoad) {
      getOneMinAnalysis();
    } else if (
      !authenticated ||
      userTypeUpdated === UserTypes.PremiumNoCredits ||
      userTypeUpdated === UserTypes.FreeNoCredits
    ) {
      setOneMinAnalysisLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticker, authenticated, user, oneMinAnalysis, aiAnalysesReady]);

  const location = useLocation();
  const handleSignUpClick = () => {
    ReactGA.event('AI SignUpClick', {
      category: 'New Feature Sign up',
      action: 'Click',
      label: 'AI Analysis',
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [visitedCompanies, setVisitedCompanies] = useState(getVisitedCompanies());

  const VISITED_COMPANIES_LIMIT = 3;

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    if (!ticker) return;

    addToVisitedCompanies(ticker);
    setVisitedCompanies(getVisitedCompanies());
  }, [ticker]);

  useEffect(() => {
    const getCompanyData = async () => {
      try {
        setLoading(true);
        // add detail=1 param to indicate a company detail is opened
        const result = await axios.get<any>(`${endpoints.companies.get}/${ticker}?detail=1`);
        setCompany(result.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setLoading(false);
      }
    };

    const getCompanyDocuments = async () => {
      try {
        const result = await axios.get<any>(`${endpoints.companies.documents}/${ticker}`);
        setCompanyDocuments(result.data);

        if (!result.data.earnings_call_analysis) {
          onAnalysisReady('earningsCall');
        }
      } catch (error) {
        console.error('Error fetching company documents:', error);
      }
    };

    const loadCompanyContext = async () => {
      if (userType !== UserTypes.Anonymous) {
        try {
          const endpoint = `/api/custom-analysis/documents/${ticker}?parse_documents=true`;
          setCompanyContextLoading(true);
          const response = await axios.get(endpoint);
          console.log('Company context loaded, response:', response.data);
          setCompanyContext(response.data);
        } finally {
          setCompanyContextLoading(false);
        }
      }
    };

    getCompanyData();
    getCompanyDocuments();
    loadCompanyContext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [currentSection, setCurrentSection] = useState('company-info');
  const sectionEnterTimeRef = useRef(Date.now());

  const setCurrentSectionHandler = (section: string) => {
    ReactGA.event('CompanySectionClick', {
      category: 'Company',
      action: 'Click',
      label: currentSection,
    });
    setCurrentSection(section);
    scrollToElement({ hash: `#${section}` }, 'center', true);
  };

  useEffect(() => {
    if (company.ticker !== '' && !loading && location.hash) {
      setTimeout(() => {
        setCurrentSectionHandler(location.hash.substring(1));
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company, loading, location.hash]);

  const NAV_ITEMS = useMemo(
    () => [
      {
        subheader: '',
        items: [
          { title: 'Overview', path: '#company-info' },
          { title: '1-min Analysis', path: '#one-min-analysis' },
          { title: 'Main Ratios', path: '#main-ratios' },
          { title: 'Valuation', path: '#valuation' },
          { title: 'Latest News', path: '#news-summary' },
          { title: 'Business', path: '#business-model' },
          { title: 'Growth', path: '#growth' },
          { title: 'Financial Strength', path: '#financial-strength' },
          { title: 'Insider Trading', path: '#insider-trading' },
          { title: 'Earnings Call Insights', path: '#earnings-call' },
          { title: 'Financials', path: '#financials' },
          { title: 'Reports Fillings', path: '#annual-quarterly-reports' },
        ],
      },
    ],
    []
  );

  // Debounced handleScroll function
  const handleScroll = debounce(() => {
    let currentSectionId = '';
    console.log('Scrolling...');

    // calculate the offsetTop and offsetHeight of each section
    const sectionOffsets = NAV_ITEMS[0].items.map((item) => {
      const elementId = item.path.substring(1); // Remove '#' from path to get ID
      const element = document.getElementById(elementId); // Remove '#' from path to get ID
      // get element's parent element with className = item.path.substring(1) to get the height of the parent
      const parent = document.getElementsByClassName(elementId)[0];
      return {
        id: item.path.substring(1),
        offsetTop: element ? element.offsetTop : 0,
        // height is the height of the parent element
        offsetHeight: parent ? parent.clientHeight : 0,
      };
    });

    // get the current scroll position
    const scrollPosition = window.scrollY + window.innerHeight / 2; // Adjust as needed

    // find the current section id
    // eslint-disable-next-line no-restricted-syntax
    for (const section of sectionOffsets) {
      if (
        scrollPosition >= section.offsetTop &&
        scrollPosition <= section.offsetTop + section.offsetHeight
      ) {
        currentSectionId = section.id;
        break;
      }
    }

    // if the current section id is different from the previous one, send a timing event
    if (currentSectionId && currentSectionId !== currentSection) {
      const timeSpentSec = Math.round((Date.now() - sectionEnterTimeRef.current) / 1000);
      sectionEnterTimeRef.current = Date.now();
      setCurrentSection(currentSectionId);
      gaTimingEvent(currentSection, timeSpentSec);
    }
  }, 100); // Debounce delay of 100ms

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, [NAV_ITEMS, handleScroll]);

  const overall_score = company.score ? parseFloat(fShortenNumber(company.score)) : 0;
  const price_score = company.score_data?.price_data.score
    ? parseFloat(fShortenNumber(company.score_data?.price_data.score))
    : 0;
  const solvency_score = company.score_data?.solvency_data.score
    ? parseFloat(fShortenNumber(company.score_data?.solvency_data.score))
    : 0;
  const growth_score = company.score_data?.growth_data?.score
    ? parseFloat(fShortenNumber(company.score_data?.growth_data.score))
    : defaultGrowthScore;
  const efficiency_score = company.score_data?.efficiency_data.score
    ? parseFloat(fShortenNumber(company.score_data?.efficiency_data.score))
    : 0;
  const quick_numbers = getQuickNumbersForCompany(company);

  const radarChartData = {
    categories: ['Valuation', 'Financial Strength', 'Future Growth', 'Efficiency'],
    series: [
      { name: 'Series 1', data: [price_score, solvency_score, growth_score, efficiency_score] },
    ],
  };

  const renderCompanyHeader = (comp: ScoredCompany2) => {
    const path = `../../assets/images/company_logos/${comp.ticker}.png`;
    return (
      <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
          sx={{ width: '100%' }}
        >
          <Stack direction="row">
            <CompanyLogo ticker={comp.ticker} logo_url={comp.image_url} width={60} height={60} />
            <Box sx={{ ml: '20px' }}>
              <Box>
                <Typography id="company-info" variant="h5" sx={{ color: 'text.primary', mb: 1 }}>
                  {comp.company_name}
                </Typography>
                <Typography variant="body1" sx={{ color: 'text.secondary' }}>
                  ${comp.ticker}
                </Typography>
              </Box>
            </Box>
          </Stack>

          {/* <Box> */}
          {/*  <Iconify icon="line-md:star-filled"  width={36} height={36} style={{ color: '#00AB55' }} /> */}
          {/* </Box> */}
        </Stack>

        <GeneratedOn
          dateToDisplay={comp.score_updated}
          textVariant="caption"
          textPrefix="Data last updated"
          onlyDate
        />
        <Box sx={{ mb: 0 }}>
          <Grid container spacing={1} justifyContent="space-around" alignItems="center">
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Sector
              </Typography>
              <Typography
                className="desktop-sector"
                variant="body2"
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'none',
                    md: 'none',
                    lg: 'block',
                    xl: 'block',
                  },
                  color: 'text.primary',
                }}
              >
                {`${comp.sector} (${comp.industry})`}
              </Typography>
              <Typography
                className="mobile-sector"
                variant="body2"
                sx={{
                  display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'block',
                    lg: 'none',
                    xl: 'none',
                  },
                  color: 'text.primary',
                }}
              >
                {comp.sector}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Market Cap
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {comp.market_cap ? fShortenNumber(comp.market_cap) : '-'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                CEO
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {comp.ceo}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Employees
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {comp.full_time_employees ? fNumber(comp.full_time_employees) : '-'}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Website
              </Typography>
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                {/* if not comp.website return "-" */}
                {!comp.website ? '-' : ''}

                {/* display website without https:// and truncate trailing chars after the 30th */}
                {comp.website && (
                  <Link
                    href={comp.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    variant="subtitle2"
                  >
                    {comp.website.length > 25
                      ? `${comp.website.replace(/(^\w+:|^)\/\//, '').substring(0, 25)}...`
                      : comp.website.replace(/(^\w+:|^)\/\//, '')}
                  </Link>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Country
              </Typography>

              {/* if not comp.country return "-" */}
              {!comp.country ? '-' : ''}

              {/* display country flag and name */}
              {comp.country && (
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.primary',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Iconify
                    icon={`flagpack:${company.country.toLowerCase()}`}
                    sx={{ borderRadius: 0.65, width: 28, marginRight: 1 }}
                  />
                  {comp.country}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Box
            sx={{
              maxHeight: '120px', // Adjust this value to fit exactly 5 lines of text
              overflowY: 'auto', // Enable vertical scrolling
              ...customScrollbarStyles,
            }}
          >
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {company.description}
            </Typography>
          </Box>
        </Box>
      </Stack>
    );
  };

  const renderPremiumFeatures = () => (
    <>
      <Box className="valuation" sx={{ mb: 3 }}>
        <Typography id="valuation" variant="h4" sx={{ color: 'text.primary', pt: '1px', mb: 2 }}>
          Valuation
        </Typography>
        <LoginToAccess
          returnTo={`/companies/${ticker}`}
          featureDescription="the valuation analysis"
          imageUrl="/assets/images/blurred/valuation.png"
        >
          <BecomePremiumAccess
            ticker={ticker}
            returnTo={`/companies/${ticker}`}
            featureDescription="the valuation analysis"
            imageUrl="/assets/images/blurred/valuation.png"
          >
            <CompanyValuation
              ticker={ticker}
              sector={company.sector}
              currencySymbol={getCurrencySymbol(company.currency)}
              setAiAnalysesReady={onAnalysisReady}
            />
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>
      <Box className="news-summary" sx={{ mb: 3 }}>
        <Stack id="news-summary" direction="row" spacing={1}>
          <Typography variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
            Latest News Summary
          </Typography>
          <HelpIcon text="The main topics/themes that are recurring extracted from the latest news. Updated on every hour." />
        </Stack>

        <LoginToAccess
          returnTo={`/companies/${ticker}`}
          featureDescription="the latest news analysis"
          imageUrl="/assets/images/blurred/news.png"
        >
          <BecomePremiumAccess
            ticker={ticker}
            returnTo={`/companies/${ticker}`}
            featureDescription="the latest news analysis"
            imageUrl="/assets/images/blurred/news.png"
          >
            <NewsAnalysis ticker={ticker} setAiAnalysesReady={onAnalysisReady} />
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>

      <Box className="business-model" sx={{ mb: 3 }}>
        <Typography id="business-model" variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
          Business
        </Typography>
        <LoginToAccess
          returnTo={`/companies/${ticker}`}
          featureDescription="the business model analysis"
          imageUrl="/assets/images/blurred/business-model.png"
        >
          <BecomePremiumAccess
            ticker={ticker}
            returnTo={`/companies/${ticker}`}
            featureDescription="the business model analysis"
            imageUrl="/assets/images/blurred/business-model.png"
          >
            <CompanyBusiness
              ticker={ticker}
              companyContext={companyContext}
              setAiAnalysesReady={onAnalysisReady}
            />
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>

      <Box className="growth" sx={{ mb: 3 }}>
        <Typography id="growth" variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
          Growth
        </Typography>
        <LoginToAccess
          returnTo={`/companies/${ticker}`}
          featureDescription="the growth analysis"
          imageUrl="/assets/images/blurred/growth.png"
        >
          <BecomePremiumAccess
            ticker={ticker}
            returnTo={`/companies/${ticker}`}
            featureDescription="the growth analysis"
            imageUrl="/assets/images/blurred/growth.png"
          >
            <CompanyGrowth ticker={ticker} setAiAnalysesReady={onAnalysisReady} />
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>

      <Box className="financial-strength" sx={{ mb: 3 }}>
        <Typography id="financial-strength" variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
          Financial Strength
        </Typography>
        <LoginToAccess
          returnTo={`/companies/${ticker}`}
          featureDescription="the financial strength analysis"
          imageUrl="/assets/images/blurred/financial-strength.png"
        >
          <BecomePremiumAccess
            ticker={ticker}
            returnTo={`/companies/${ticker}`}
            featureDescription="the financial strength analysis"
            imageUrl="/assets/images/blurred/financial-strength.png"
          >
            <CompanyFinancialStrength ticker={ticker} setAiAnalysesReady={onAnalysisReady} />
          </BecomePremiumAccess>
        </LoginToAccess>
      </Box>

      {/* Insider Trading */}
      <Box className="insider-trading" sx={{ mb: 3 }}>
        <Typography id="insider-trading" variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
          Insider Trading <FiberNew />
        </Typography>
        <CompanyInsiderTrading ticker={ticker || ''} />
      </Box>

      {documents?.earnings_call_transcript && (
          <Box className="premium-only-label" sx={{ position: 'relative' }}>
            <Stack direction="row">
          <Typography id="earnings-call" variant="h4" sx={{ color: 'text.primary', mb: 1 }}>
            Earnings Call Insights
          </Typography>
  <HelpIcon text="Quick analysis of the latest earnings call. You can find a quick summary at the beginning and insights by different aspects below." />

            </Stack>
          {/*

<LoginToAccess returnTo={`/companies/${ticker}`}
                           featureDescription="the earnings call insights"
                           imageUrl="/assets/images/blurred/earnings-call-insights.png"
            >
              <BecomePremiumAccess ticker={ticker}
                                   returnTo={`/companies/${ticker}`}
                                   featureDescription="the earnings call insights"
                                   imageUrl="/assets/images/blurred/earnings-call-insights.png"

              >
                <EarningsCallAiAnalysis ticker={ticker}
                                        earnings_call_transcript={documents.earnings_call_transcript}/>
              </BecomePremiumAccess>
            </LoginToAccess>
                      */}
          <AnnualReportAnalysisLoginGuard>
            <EarningsCallAnalysis renderHeader={false} setAiAnalysesReady={onAnalysisReady} />
          </AnnualReportAnalysisLoginGuard>
        </Box>
      )}
    </>
  );

  const renderContent = () => (
    <Stack
      direction="column"
      sx={{ mt: 3, maxWidth: '1400px' }}
      alignItems="center"
      justifyContent="center"
    >
      <Grid key="a" className="companyLayout" container spacing={3} sx={{ minHeight: '100vh' }}>
        {/* Side Nav */}
        <Grid
          item
          xs={2}
          sx={{
            display: {
              xs: 'none',
              sm: 'none',
              md: 'none',
              lg: 'block',
              xl: 'block',
            },
            height: '100vh',
            position: 'sticky',
            top: 0,
            zIndex: theme.zIndex.drawer,
          }}
        >
          <Box>
            <Button onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </Button>
          </Box>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            sx={{ height: '100vh' }}
          >
            <CompanyNavigation
              currentSectionId={currentSection}
              setCurrentSectionId={setCurrentSectionHandler}
              navItems={NAV_ITEMS}
            />
          </Stack>
        </Grid>

        {/* Main Section on the right */}
        <Grid item xs={12} sm={12} md={12} lg={10} xl={10} sx={{ mt: 1 }}>
          <Box
            sx={{
              display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' },
              mb: 2,
            }}
          >
            <Button onClick={() => navigate(-1)}>
              <ArrowBackIosIcon />
            </Button>
          </Box>
          <Stack className="company-container" sx={{ mx: 3, maxWidth: '1200px' }} spacing={6}>
            {/*
              <Box className="company-container" sx={{mx: 3}}>
              */}
            <Box className="company-info">
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} lg={6} xl={6}>
                  {renderCompanyHeader(company)}
                </Grid>

                <Grid item className="price-chart" xs={12} lg={6} xl={6}>
                  <Box className="price-chart" sx={{ mb: 3 }}>
                    <PriceChart
                      currencySymbol={getCurrencySymbol(company.currency)}
                      prices={company.prices}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>

            {company.score && company.score_data && (
              <OneMinAnalysis
                ticker={ticker}
                oneMinAnalysis={oneMinAnalysis}
                isAnalysisLoading={oneMinAnalysisLoading}
                isAnalysisLatestLoading={oneMinAnalysisLatestLoading}
                analysisDatetimeGenerated={oneMinAnalysisDatetimeGenerated}
                overallScore={overall_score}
                radarChartData={radarChartData}
                scoreData={company.score_data}
              />
            )}

            <Box className="main-ratios" sx={{ mb: 3 }}>
              <Stack id="main-ratios" direction="row" spacing={1}>
                <Typography variant="h4" sx={{ color: 'text.primary', pt: '1px', mb: 2 }}>
                  Main Ratios
                </Typography>
                <AIAnalysis
                  ticker={ticker}
                  analysisType={AIAnalysisTypesEnum.QuickNumbers}
                  setAiAnalysesReady={onAnalysisReady}
                />
              </Stack>

              <QuickRatios
                valuation={quick_numbers.valuation}
                solvency={quick_numbers.solvency}
                efficiency={quick_numbers.efficiency}
                growth={quick_numbers.growth}
              />
            </Box>

            {renderPremiumFeatures()}

            <CompanyFinancials ticker={company.ticker} setAiAnalysesReady={onAnalysisReady} />

            {documents?.latest_annual_url || documents?.latest_quarterly_url ? (
              <Box className="annual-quarterly-reports" sx={{ mb: 3 }}>
                <Typography
                  id="annual-quarterly-reports"
                  variant="h4"
                  sx={{ color: 'text.primary', mb: 1 }}
                >
                  Annual And Quarterly Reports
                </Typography>
                <CompanyPublicDocuments
                  ticker={ticker}
                  latest_annual_url={documents.latest_annual_url}
                  latest_quarterly_url={documents.latest_quarterly_url}
                />
              </Box>
            ) : null}
          </Stack>
        </Grid>
      </Grid>

      <LoginRegisterDialog
        dialogOpen={!authenticated && visitedCompanies.length >= VISITED_COMPANIES_LIMIT}
        onClose={() => {
          setVisitedCompaniesToTwo();
          setVisitedCompanies(getVisitedCompanies());
        }}
      />
    </Stack>
  );

  const renderContentDesktop = () => (
    // this is needed for desktop to center the content
    <Stack direction="column" alignItems="center" justifyContent="center">
      {renderContent()}
    </Stack>
  );

  const customScrollbarStyles = {
    '::-webkit-scrollbar': {
      width: '6px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[300],
      borderRadius: '8px',
    },
    '::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[600],
    },
  };

  // @ts-ignore
  return company.ticker && !loading ? (
        <Box
           sx={{
      width: '100%',
      maxWidth: '100%',
      boxSizing: 'border-box', // ensures padding and border are included in the width
    }}
        >

      {isMobile ? renderContent() : renderContentDesktop()}

      {/* Show chat only to premium users for now */}
      <CreditsProvider>
        <ChatTriggerButton
          ticker={company.ticker}
          company={company}
          contextDocuments={companyContext}
          contextDocumentsLoading={companyContextLoading}
        />
      </CreditsProvider>
    </Box>
  ) : (
    <Box>
      <LinearProgress />
    </Box>
  );
}
