// React
import React, {useEffect, useState} from 'react';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {Button, Grid, TableCell, TableRow, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";

// constants
import {marketCapFormatter} from 'src/utils/formatting';
import axios, {endpoints} from '../../utils/axios';
import Iconify from '../iconify';
import AiProgressbar from "../utility_components/ai-progressbar";
import CustomTable from "../utility_components/custom-table";
import ScoreBreakdownSmall from "../utility_components/score_breakdown_small";
import CompanyLogo from "../company/constants";
import ScoreBreakdownProgressElement from "../company/score-breakdown-progress-element";
import PriceChartMinimal from "../price-chart/price-chart-minimal";
import ShowMore from "../utility_components/show-more";
import {useAuthContext} from "../../auth/hooks";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";
import PremiumOnlyLabel from '../premium-only-label/premium-only-label';


interface MagicScoreStatistics {
  earnings_yield: number;
  return_on_capital: number;
  earnings_yield_rank: number;
  return_on_capital_rank: number;
  magic_formula_score: number;
  rank: number;
}

interface TopMagicCompany {
  symbol: string;
  company_name: string;
  image: string;
  sector: string;
  country: string;
  market_cap: number;
  score: number;
  score_data: any;
  magic_formula: MagicScoreStatistics;
}

interface MagicFormulaCompaniesResponse {
  updated_date: string;
  type: string;
  companies: TopMagicCompany[];
  total_companies: number;
}

const tableFontSize = "0.8rem";
const tableFontSizeSmall = "0.7rem";

export default function MagicCompanies() {
  const {user, authenticated} = useAuthContext();
  const [becomePremiumOpen, setBecomePremiumOpen] = useState(false);
  const [magicCompaniesResponse, setMagicCompanies] = useState<MagicFormulaCompaniesResponse>({
    updated_date: "",
    type: "",
    companies: [],
    total_companies: 0,
  });
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const blurStyle = {filter: 'blur(5px)', cursor: "pointer"};
  const isPremium = user?.subscription && user.subscription?.is_active;
  const blurred = (!authenticated || !isPremium);

  useEffect(() => {
    const getMagicCompanies = async () => {
      try {
        setLoading(true);
        const result = await axios.get<any>(`${endpoints.curatedStocks}?type=magic_formula&detail=1`);
        console.log("Top companies result:", result.data);
        setMagicCompanies(result.data);
      } catch (error) {
        console.error("Error fetching top companies:", error);
      } finally {
        setLoading(false);
      }
    };
    getMagicCompanies();
  }, []);

  if (loading) {
    return (
      <AiProgressbar text="Top companies loading..."/>
    );
  }

  const headers = [
    {display: "", key: "logo"},
    {display: "Name", key: "name"},
    {display: "Country", key: "country"},
    {display: "ValueHunter Score", key: "score"},
    {display: "Market Cap", key: "market_cap"},
    {display: "Rank (Magic Score)", key: "rank"},
    {display: "Return on Capital", key: "return_on_capital"},
    {display: "Earnings Yield", key: "earnings_yield"},

  ];

  const prepareRawData = (insiderCompanies: TopMagicCompany[]) => insiderCompanies.map((company, index) => ({
    "logo": company.image,
    "name": company.company_name,
    "sector": company.sector,
    "country": company.country,
    "ticker": company.symbol,
    "score": company.score,
    "score_data": company.score_data,
    "market_cap": company.market_cap,
    "rank": company.magic_formula.rank,
    "magic_score": company.magic_formula.magic_formula_score,
    "return_on_capital": company.magic_formula.return_on_capital,
    "earnings_yield": company.magic_formula.earnings_yield,
    "return_on_capital_rank": company.magic_formula.return_on_capital_rank,
    "earnings_yield_rank": company.magic_formula.earnings_yield_rank,
    index
  }))

  const renderRow = (row: any, index: number) => (
    <TableRow
      key={index}
      component='div'
      // @ts-ignore
      onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
      sx={{
        maxHeight: "50px", whiteSpace: 'nowrap',
        '&:last-child td, &:last-child th': {border: 0},
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.action.hover,
        },
        textDecoration: 'none',
        color: 'inherit'
      }}
    >
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <CompanyLogo ticker={row.ticker} logo_url={row.logo}/>
      </TableCell>
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            fontSize: tableFontSize,
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.name}
        </Typography>
        <Typography sx={{
          color: 'text.secondary',
          fontSize: tableFontSize,
        }}>${row.ticker}</Typography>
        <Typography sx={{color: 'text.secondary', fontSize: tableFontSizeSmall}}>{row.sector}</Typography>
      </TableCell>

      <TableCell align="center">
        <Tooltip title={row.country}>
          <Box component="span">
            <Iconify icon={`flagpack:${row.country?.toLowerCase()}`}
                     sx={{borderRadius: 0.65, width: 28}}/>
          </Box>
        </Tooltip>
      </TableCell>

      <TableCell align="center">
        <ScoreBreakdownSmall score={row.score} score_data={row.score_data}/>
      </TableCell>
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          ${marketCapFormatter(row.market_cap)}
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          #{row.rank} ({row.magic_score})
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize}}>
          {row.return_on_capital?.toFixed(1)}%
        </Typography>
        <Typography sx={{fontSize: tableFontSize}}>
          #{row.return_on_capital_rank} (out of {magicCompaniesResponse.total_companies})
        </Typography>
      </TableCell>

      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize}}>
          {row.earnings_yield?.toFixed(1)}%
        </Typography>
        <Typography sx={{fontSize: tableFontSize}}>
          #{row.earnings_yield_rank} (out of {magicCompaniesResponse.total_companies})
        </Typography>
      </TableCell>
    </TableRow>
  );

  const renderRowMobile = (row: any, index: number) => (
    <Box
      key={index}
      onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
      sx={{
        backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.action.selected,
        p: 1,
      }}
    >
      <Stack direction="column">
        {/* logo, name and ticker */}
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack direction="row" sx={blurred ? blurStyle : {}}>
            <CompanyLogo ticker={row.ticker} logo_url={row.logo}/>
            <Stack direction="column" sx={{ml: 1}}>
              <Typography sx={{fontSize: tableFontSize, fontWeight: 'bold'}}>
                {row.name}
              </Typography>
              <Typography sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                {row.symbol}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="soft"
              onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
              endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={20}/>}
            >
              Open
            </Button>
          </Box>
        </Stack>


        <Grid container spacing={2} sx={{mt: 3}}>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
              Magic Score
            </Typography>
            <Typography variant="body2"
                        sx={{fontSize: tableFontSize, fontWeight: "bold"}}>#{row.rank}({row.magic_score})</Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
              Country
            </Typography>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Iconify icon={`flagpack:${row.country.toLowerCase()}`}
                       sx={{borderRadius: 0.65, width: 16}}/>
              <Typography variant="body2" sx={{fontSize: tableFontSize}}>
                {row.country}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              color: 'text.secondary',
            }}> Sector </Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, ...blurred ? blurStyle : {}}}>
              {row.sector}
            </Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
              Market Cap
            </Typography>
            <Typography variant="body2"
                        sx={{fontSize: tableFontSize, ...blurred ? blurStyle : {}}}>${marketCapFormatter(row.market_cap)}</Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary', mt: 1}}>
              Return on Capital
            </Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              fontWeight: "bold"
            }}> {row.return_on_capital?.toFixed(1)}%</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>Overall
              Score</Typography>
            <Typography variant="body2" fontWeight="bold"
                        sx={{fontSize: tableFontSize}}>{row?.score?.toFixed(2)}</Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              color: 'text.secondary'
            }}>Breakdown</Typography>

            <Stack direction="column" spacing={0.1}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>V</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.price_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>F</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.solvency_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>E</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.efficiency_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>G</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.growth_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
            </Stack>

            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary', mt: 5.6}}>
              Earnings Yield
            </Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              fontWeight: "bold"
            }}>{row.earnings_yield?.toFixed(1)}%</Typography>

          </Grid>
        </Grid>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <PriceChartMinimal prices={row.priceLast3Months} width="100%" height="80%"/>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Stack direction="column" sx={{p: 4}}>
      <Box sx={{mb: 2}}>
        <Stack direction="row" spacing={0.8}>
          <Typography variant="h4" sx={{mb: 2}}>Top Magic Formula Companies</Typography>
          <PremiumOnlyLabel/>
        </Stack>
        <Typography variant="body2">
          <a
            href="https://en.wikipedia.org/wiki/Magic_formula_investing" target="_blank" rel="noopener noreferrer"
            style={{color: 'inherit', fontWeight: 'bold'}}>Greenblatt’s Magic Formula (click to learn more)</a> is a
          strategy designed to identify <strong>good businesses at bargain prices</strong>. The formula ranks companies
          based on their <strong>earnings yield</strong> and <strong>return on capital</strong>.
        </Typography>
        <Typography variant="body2">
          High earnings yield indicates that the stock is priced attractively compared to its earnings, while high
          return
          on capital highlights the company’s ability to generate returns on its investments effectively.
        </Typography>

        <Typography variant="body2" fontWeight="bold" sx={{mt: 1}}>
          How is the Magic Score calculated?
        </Typography>
        <ShowMore expandText="Show More" collapseText="Hide" doubleButton={false}>
          <Typography variant="body2">
            The Return on Capital (ROC) and Earnings Yield are calculated for each company. Then the companies are
            ranked based on their ROC and Earnings Yield. The Magic Score is the sum of the ranks of the two metrics.
          </Typography>
          <Typography variant="body2">
            Return on Capital (ROC): <i>EBIT ÷ (Net Working Capital + Net Fixed Assets)</i>
            <br/>
            Earnings Yield: <i>EBIT ÷ Enterprise Value</i>
            <br/>
          </Typography>
          <Typography variant="body2" fontWeight="bold" sx={{mt: 1}}>
            How are the companies selected?
          </Typography>
          <Typography variant="body2">
            Companies with market cap <strong>below $1 billion</strong> and companies in the <strong>Financials
            Services</strong> and <strong>Utilities sectors are excluded </strong>.
          </Typography>
          <Typography variant="body2" sx={{mb: 2}}>
            In the table below, companies with the <strong>highest Magic Score</strong> are on top. Click on the headers
            to change the sorting.
          </Typography>
        </ShowMore>
      </Box>

      {!loading && (
        <CustomTable headers={headers} rawData={prepareRawData(magicCompaniesResponse.companies)}
                     renderRow={(row: any, index: number) => renderRow(row, index)}
                     renderRowMobile={(row: any, index: number) => renderRowMobile(row, index)}
                     rowsPerPage={50}
                     initialSortConfig={{column: "rank", order: "asc"}}
        />
      )}
      <BecomePremiumToAccessDialog ticker={undefined} open={becomePremiumOpen}
                                   onClose={() => setBecomePremiumOpen(false)} premiumOnly/>
    </Stack>

  );
};
