// React
import React, {useEffect, useState} from 'react';
// @mui
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import {Button, Grid, TableCell, TableRow, Tooltip} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Link, useNavigate} from "react-router-dom";

// constants
import {marketCapFormatter} from 'src/utils/formatting';
import axios, {endpoints} from '../../utils/axios';
import Iconify from '../iconify';
import AiProgressbar from "../utility_components/ai-progressbar";
import CustomTable from "../utility_components/custom-table";
import ScoreBreakdownSmall from "../utility_components/score_breakdown_small";
import CompanyLogo, {shouldUseLocalLogo} from "../company/constants";
import ScoreBreakdownProgressElement from "../company/score-breakdown-progress-element";
import PriceChartMinimal from "../price-chart/price-chart-minimal";
import ShowMore from "../utility_components/show-more";
import {BecomePremiumToAccessDialog} from "../utility_components/become-premium-to-access-dialog";
import {useAuthContext} from "../../auth/hooks";
import PremiumOnlyLabel from '../premium-only-label/premium-only-label';

interface PriceTargetStatistics {
  symbol: string;
  targetHigh: number;
  targetLow: number;
  targetConsensus: number;
  targetMedian: number;
}

interface TopPriceTargetCompany {
  symbol: string;
  company_name: string;
  image: string;
  sector: string;
  country: string;
  market_cap: number;
  price: number;
  positive_price_diff: number;
  positive_price_percentage_diff: number;
  score: number;
  score_data: any;
  price_target: PriceTargetStatistics;
  analyst_count: number;
}

interface PositivePriceTargetCompaniesResponse {
  updated_date: string;
  type: string;
  companies: TopPriceTargetCompany[];
}

const tableFontSize = "0.8rem";
const tableFontSizeSmall = "0.7rem";

export default function PriceTargetCompanies() {
  const {user, authenticated} = useAuthContext();
  const [priceTargetCompaniesResponse, setPriceTargetCompanies] = useState<PositivePriceTargetCompaniesResponse>({
    updated_date: "",
    type: "",
    companies: [],
  });
  const [loading, setLoading] = useState(true);
  const [becomePremiumOpen, setBecomePremiumOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();

  const blurStyle = {filter: 'blur(5px)', cursor: "pointer"};
  const isPremium = user?.subscription && user.subscription?.is_active;
  const blurred = (!authenticated || !isPremium);


  useEffect(() => {
    const getPriceTargetCompanies = async () => {
      try {
        setLoading(true);
        const result = await axios.get<any>(`${endpoints.curatedStocks}?type=consensus_positive_difference&detail=1`);
        console.log("Top price target companies result:", result.data);
        setPriceTargetCompanies(result.data);
      } catch (error) {
        console.error("Error fetching top companies:", error);
      } finally {
        setLoading(false);
      }
    };
    getPriceTargetCompanies();
  }, []);

  if (loading) {
    return (
      <AiProgressbar text="Top companies loading..."/>
    );
  }

  const headers = [

    {display: "", key: "logo"},
    {display: "Name", key: "name"},
    {display: "Country", key: "country"},
    {display: "ValueHunter Score", key: "score"},
    {display: "Market Cap", key: "market_cap"},
    {display: "Price", key: "price"},
    {display: "Price Target Consensus", key: "price_target_consensus"},
    {display: "Price Target Difference", key: "price_diff"},
    {display: "Price Target Low", key: "price_low"},
    {display: "Price Target High", key: "price_high"},
    {display: "Analyst Count", key: "analyst_count"},
  ];

  const prepareRawData = (insiderCompanies: TopPriceTargetCompany[]) => insiderCompanies.map((company, index) => ({
    "logo": company.image,
    "name": company.company_name,
    "sector": company.sector,
    "country": company.country,
    "ticker": company.symbol,
    "score": company.score,
    "score_data": company.score_data,
    "market_cap": company.market_cap,
    "price": company.price,
    "price_target_consensus": company.price_target.targetConsensus,
    "price_low": company.price_target.targetLow,
    "price_high": company.price_target.targetHigh,
    "price_diff": company.positive_price_percentage_diff,
    "analyst_count": company.analyst_count,
    index
  }))

  const renderRow = (row: any, index: number) => (
    <TableRow
      key={index}
      component='div'
      // @ts-ignore
      onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
      sx={{
        maxHeight: "50px", whiteSpace: 'nowrap',
        '&:last-child td, &:last-child th': {border: 0},
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.action.hover,
        },
        textDecoration: 'none',
        color: 'inherit'
      }}
    >
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <CompanyLogo ticker={row.ticker} logo_url={row.logo}/>
      </TableCell>

      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            fontSize: tableFontSize,
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.name}
        </Typography>
        <Typography sx={{
          color: 'text.secondary',
          fontSize: tableFontSize,
        }}>${row.ticker}</Typography>
        <Typography sx={{color: 'text.secondary', fontSize: tableFontSizeSmall}}>{row.sector}</Typography>
      </TableCell>

      <TableCell align="center">
        <Tooltip title={row.country}>
          <Box component="span">
            <Iconify icon={`flagpack:${row.country?.toLowerCase()}`}
                     sx={{borderRadius: 0.65, width: 28}}/>
          </Box>
        </Tooltip>
      </TableCell>

      <TableCell align="center">
        <ScoreBreakdownSmall score={row.score} score_data={row.score_data}/>
      </TableCell>
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography sx={{fontSize: tableFontSize}}>
          ${marketCapFormatter(row.market_cap)}
        </Typography>
      </TableCell>

      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          ${row.price.toFixed(1)}
        </Typography>
      </TableCell>
      <TableCell align="center" sx={blurred ? blurStyle : {}}>
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          ${row.price_target_consensus.toFixed(1)}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          {row.price_diff.toFixed(1)}%
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize}}>
          ${row.price_low}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize}}>
          ${row.price_high}
        </Typography>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{fontSize: tableFontSize, fontWeight: "bold"}}>
          {row.analyst_count}
        </Typography>
      </TableCell>

    </TableRow>
  );

  const renderRowMobile = (row: any, index: number) => (
    <Box
      key={index}
      onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
      sx={{
        backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.action.selected,
        p: 1,
      }}
    >
      <Stack direction="column">
        {/* logo, name and ticker */}
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack direction="row" sx={blurred ? blurStyle : {}}>
            <CompanyLogo ticker={row.ticker} logo_url={row.logo}/>
            <Stack direction="column" sx={{ml: 1}}>
              <Typography sx={{fontSize: tableFontSize, fontWeight: 'bold'}}>
                {row.name}
              </Typography>
              <Typography sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
                {row.symbol}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="soft"
              onClick={() => blurred ? setBecomePremiumOpen(true) : navigate(`/companies/${row.ticker}`)}
              endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={20}/>}
            >
              Open
            </Button>
          </Box>
        </Stack>


        <Grid container spacing={2} sx={{mt: 3}}>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
              Country
            </Typography>

            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Iconify icon={`flagpack:${row.country.toLowerCase()}`}
                       sx={{borderRadius: 0.65, width: 16}}/>
              <Typography variant="body2" sx={{fontSize: tableFontSize}}>
                {row.country}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              color: 'text.secondary'
            }}> Sector </Typography>
            <Typography variant="body2"
                        sx={{fontSize: tableFontSize, ...blurred ? blurStyle : {}}}>{row.sector}</Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>
              Market Cap
            </Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, ...blurred ? blurStyle : {}}}>
              ${marketCapFormatter(row.market_cap)}
            </Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary', mt: 1}}>
              Price
            </Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              fontWeight: "bold",
              ...blurred ? blurStyle : {}
            }}> {row.price}</Typography>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary', mt: 1}}>
              Price Target Difference
            </Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              fontWeight: "bold",
            }}> {row.price_diff?.toFixed(1)}%</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary'}}>Overall
              Score</Typography>
            <Typography variant="body2" fontWeight="bold"
                        sx={{fontSize: tableFontSize}}>{row?.score?.toFixed(2)}</Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              color: 'text.secondary'
            }}>Breakdown</Typography>

            <Stack direction="column" spacing={0.1}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>V</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.price_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>F</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.solvency_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>E</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.efficiency_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{fontSize: 10}}>G</Typography>
                <ScoreBreakdownProgressElement score={row.score_data?.growth_data?.score}
                                               heightParam="8px" widthParam="40px"
                                               sx={{mx: 0.2}}/>
              </Stack>
            </Stack>

            <Typography variant="body2" sx={{fontSize: tableFontSize, color: 'text.secondary', mt: 1}}>
              Price Target Consensus
            </Typography>
            <Typography variant="body2" sx={{
              fontSize: tableFontSize,
              fontWeight: "bold",
              ...blurred ? blurStyle : {}
            }}>{row.price_target_consensus}</Typography>

          </Grid>
        </Grid>
        <Stack direction="column" alignItems="center" justifyContent="center">
          <PriceChartMinimal prices={row.priceLast3Months} width="100%" height="80%"/>
        </Stack>
      </Stack>
    </Box>
  );

  return (
    <Stack direction="column" sx={{p: 4}}>
      <Box sx={{mb: 2}}>
        <Stack direction="row" spacing={0.8}>
          <Typography variant="h4" sx={{mb: 2}}>Undervalued Stocks According To Analysts</Typography>
          <PremiumOnlyLabel/>
        </Stack>
        <Typography variant="body2">
          <strong>Positive Price Target Difference</strong> is a strategy that identifies companies with
          significant upside potential based on analyst predictions.
          <br/>The approach focuses on stocks where the analysts’
          average price target exceeds the current market price, signaling potential undervaluation and growth
          opportunities.
        </Typography>
        <Typography variant="body2" fontWeight="bold" sx={{mt: 1}}>
          How price target consensus is calculated?
        </Typography>
        <ShowMore expandText="Show More" collapseText="Hide" doubleButton={false}>
          <Typography variant="body2">
            Target Price Consensus is the average price target set by analysts covering the stock in the last 6 months.
          </Typography>
          <Typography variant="body2" fontWeight="bold" sx={{mt: 1}}>
            How are the companies selected?
          </Typography>
          <Typography variant="body2">
            Companies in the <strong>Healthcare</strong> sector are excluded. Stocks without analyst coverage in the
            <strong> last month</strong> are also excluded.
          </Typography>
          <Typography variant="body2" sx={{mb: 2}}>
            Companies are sorted by the percentage difference between the current price and the target price.
          </Typography>
        </ShowMore>

      </Box>

      {!loading && (
        <CustomTable headers={headers} rawData={prepareRawData(priceTargetCompaniesResponse.companies)}
                     renderRow={(row: any, index: number) => renderRow(row, index)}
                     renderRowMobile={(row: any, index: number) => renderRowMobile(row, index)}
                     rowsPerPage={25}
                     initialSortConfig={{column: "price_diff", order: "desc"}}
        />

      )}
      <BecomePremiumToAccessDialog ticker={undefined} open={becomePremiumOpen}
                                   onClose={() => setBecomePremiumOpen(false)} premiumOnly/>
    </Stack>

  );
};
