import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import React from 'react';

import Label from '../label';
import Iconify from '../iconify';

export default function PremiumOnlyLabel(sx: any) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  return (
    <Box sx={{ width: isMobile ? '140px' : 'auto', mb: 2, ...sx }}>
      <Label color="success" variant="soft" startIcon={<Iconify icon="ri:medal-fill" />}>
        Premium {!isMobile && 'Only'}
      </Label>
    </Box>
  );
}
