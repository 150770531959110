import React from "react";
import {Box, Card, Collapse, Grid, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

import {NewsAnalysisType} from "./news_news";
import NewsContainer from "./news_container";
import Label from "../label";
import Iconify from "../iconify";
import {customScrollbarStyles} from "../../theme/css";

interface NewsThemesProps {
  newsAnalysis: NewsAnalysisType;
  themeIdToShowNews: boolean[];
  setThemeIdToShowNews: (callback: (prevState: boolean[]) => boolean[]) => void;
  renderCompact?: boolean;
}

const hoverStyles = {
  transition: 'transform 0.3s ease-in-out',
  "&:hover": {
    cursor: "pointer",
    transform: 'scale(1.01)'
  },
  '&:hover .theme-title': {
    color: 'primary.main'
  }
};

const getLabelElement = (sentiment: string) => {
  switch (sentiment) {
    case "good":
      return <Label color="success"> Good </Label>
    case "bad":
      return <Label color="error"> Bad </Label>
    case "neutral":
      return <Label color="info"> Neutral </Label>
    default:
      return <Label color="info"> Neutral </Label>
  }
}

export default function NewsThemes({
  newsAnalysis,
  themeIdToShowNews,
  setThemeIdToShowNews,
  renderCompact = false
}: NewsThemesProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const renderThemesMobile = () => (
    <>
      {newsAnalysis.themes.map((t, index) => (
        <Box key={t.theme_title} sx={{mt: 2, mx: 2}}>
          <Stack direction="row" spacing={1} onClick={() => {
            setThemeIdToShowNews(prevState => {
              const newState = [...prevState];
              newState[index] = !newState[index];
              return newState;
            });
          }}
                 sx={{mt: 1, ...hoverStyles}}
          >
            {getLabelElement(t.label)}
            <Typography className="theme-title" variant="body2"
                        fontWeight="bold">{t.theme_title}</Typography>
            <Box>
              {themeIdToShowNews[index] ? <Iconify icon="eva:chevron-up-fill" width={24}/> :
                <Iconify icon="eva:chevron-down-fill" width={24}/>}
            </Box>
          </Stack>
          <Typography variant="body2" sx={{my: 2, mb: 1}}>{t.theme_analysis}</Typography>
          <Button variant="text" color="primary"
                  onClick={() => {
                    setThemeIdToShowNews(prevState => {
                      const newState = [...prevState];
                      newState[index] = !newState[index];
                      return newState;
                    });
                  }}
                  sx={{fontSize: 11, ml: 1}}
          >
            {themeIdToShowNews[index] ? "Hide News" : "Show News"}
          </Button>
          <Collapse in={themeIdToShowNews[index]}>
            <NewsContainer
              news={newsAnalysis.news.filter((n) => newsAnalysis.themes[index].news_ids.includes(n.id))}/>
          </Collapse>
        </Box>
      ))}
    </>
  );

  const renderThemesDesktop = () => (
    <>
      {newsAnalysis.themes.map((t, index) => (
        <Card key={t.theme_title} sx={{my: 2, mx: 1}}>
          <Grid container spacing={1} sx={{width: '100%', p: 3}}>
            <Grid item xs={4}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                  Recurring Theme
                </Typography>
                <Iconify icon="mdi:lightbulb-on-outline" width={20}/>
              </Stack>

              <Stack direction="row" spacing={1} sx={{mt: 1, ...hoverStyles}}>
                {getLabelElement(t.label)}
                <Typography className="theme-title" variant="body2"
                            fontWeight="bold">{t.theme_title}</Typography>
              </Stack>
              <Typography variant="body2" sx={{my: 2, mb: 1}}>{t.theme_analysis}</Typography>
            </Grid>
            <Grid item xs={8}>
              <Stack direction="row" spacing={1}>
                <Typography variant="body1" sx={{fontWeight: 'bold'}}>
                  News
                </Typography>
                <Iconify icon="material-symbols:news-outline" width={20}/>
              </Stack>
              <Box sx={{
                maxHeight: '300px',
                overflowY: 'auto',
                width: "100%",
                ...customScrollbarStyles(theme)
              }}>
                <NewsContainer
                  news={newsAnalysis.news.filter((n) => newsAnalysis.themes[index].news_ids.includes(n.id))}/>
              </Box>
            </Grid>
          </Grid>
        </Card>
      ))}
    </>
  );

  return isMobile || renderCompact ? renderThemesMobile() : renderThemesDesktop();
} 