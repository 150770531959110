import React, { useState, FC } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, Card, CardHeader, Stack, Typography, useMediaQuery } from '@mui/material';
import numeral from 'numeral';
import {
  ResponsiveContainer,
  LineChart,
  BarChart,
  Line,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ReferenceLine,
  Label,
  AreaChart,
  Area,
  ComposedChart,
  Legend,
} from 'recharts';
import CardContent from '@mui/material/CardContent';

import { useAuthContext } from 'src/auth/hooks';
import { BecomePremiumToAccessContent } from '../utility_components/become-premium-to-access-content';
import { isPremiumUser } from './utils';

interface Historical {
  date: string;
  price: number;
}

export interface HistoricalPe extends Historical {
  pe: number;
  eps: number;
}

export interface HistoricalPb extends Historical {
  pb: number;
  book_per_share: number;
}

export interface HistoricalPs extends Historical {
  ps: number;
  sales: number;
}

export interface HistoricalPfcf extends Historical {
  pfcf: number;
  fcf: number;
}

export interface HistoricalData {
  pe: {
    annual: {
      ratios: HistoricalPe[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
    quarterly: {
      ratios: HistoricalPe[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
  };
  pb: {
    annual: {
      ratios: HistoricalPb[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
    quarterly: {
      ratios: HistoricalPb[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
  };
  ps: {
    annual: {
      ratios: HistoricalPs[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
    quarterly: {
      ratios: HistoricalPs[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
  };
  pfcf: {
    annual: {
      ratios: HistoricalPfcf[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
    quarterly: {
      ratios: HistoricalPfcf[];
      mean_5y: number;
      mean_10y: number;
      mean_20y: number;
    };
  };
}

type HistoricalUnion = HistoricalPe | HistoricalPb | HistoricalPs | HistoricalPfcf;
type RatioKey = 'pe' | 'pb' | 'ps' | 'pfcf';
type TimeFrame = 'annual' | 'quarterly';

// Metric labels (capitalized)
const metricLabels: Record<string, string> = {
  pe: 'P/E',
  eps: 'Earnings Per Share',
  pb: 'P/B',
  book_per_share: 'Book Value Per Share',
  ps: 'P/S',
  sales: 'Sales Per Share',
  pfcf: 'P/FCF',
  fcf: 'Free Cash Flow Per Share',
  price: 'Price',
};

const CompanyValuationHistoricalChart2: FC<{
  historicalData: HistoricalData;
}> = ({ historicalData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const successLight: string = theme.palette.primary.light;
  const secondaryMain: string = theme.palette.secondary.light;
  const errorLight: string = theme.palette.error.light;
  const warningLight: string = theme.palette.warning.light;
  const { user } = useAuthContext();
  const isPremium = isPremiumUser(user);

  const [selectedRatio, setSelectedRatio] = useState<RatioKey>('pe');
  const [timeFrame, setTimeFrame] = useState<TimeFrame>('annual');
  const [yearRange, setYearRange] = useState<number>(5);

  const [showPremiumOverlay, setShowPremiumOverlay] = useState<boolean>(false);
  const [ticker, setTicker] = useState<string | undefined>(undefined);

  // Get bar color based on value
  const getBarColor = (value: number): string => {
    if (value < 0) return errorLight;
    if (value === 0) return warningLight;
    return successLight;
  };

  // Handle timeframe change
  const handleTimeFrameChange = (newTimeFrame: TimeFrame) => {
    setTimeFrame(newTimeFrame);
    if (newTimeFrame === 'quarterly') {
      setYearRange(5);
    }
  };

  // Handle year range change
  const handleYearRangeChange = (newYearRange: number) => {
    if (!isPremium && (newYearRange === 10 || newYearRange === 20)) {
      setShowPremiumOverlay(true);
    } else {
      setShowPremiumOverlay(false);
    }
    setYearRange(newYearRange);
  };

  // Mapping for each ratio option with display names and keys for the main and secondary metrics.
  const ratioOptions: Record<
    RatioKey,
    {
      displayName: string;
      mainKey: string;
      secondaryKey: string;
    }
  > = {
    pe: {
      displayName: 'Price To Earnings (P/E)',
      mainKey: 'pe',
      secondaryKey: 'eps',
    },
    pb: {
      displayName: 'Price To Book (P/B)',
      mainKey: 'pb',
      secondaryKey: 'book_per_share',
    },
    ps: {
      displayName: 'Price To Sales (P/S)',
      mainKey: 'ps',
      secondaryKey: 'sales',
    },
    pfcf: {
      displayName: 'Price To Free Cashflow (P/FCF)',
      mainKey: 'pfcf',
      secondaryKey: 'fcf',
    },
  };

  // Get the data and sort by date (oldest first so that most recent is on the right)
  const sortedData = (() => {
    if (
      !historicalData ||
      !historicalData[selectedRatio] ||
      !historicalData[selectedRatio][timeFrame] ||
      !historicalData[selectedRatio][timeFrame].ratios
    ) {
      return [];
    }

    const data = historicalData[selectedRatio][timeFrame].ratios;
    const currentDate = new Date();
    const cutoffDate = new Date();
    cutoffDate.setFullYear(currentDate.getFullYear() - yearRange);

    return [...data]
      .filter((item) => new Date(item.date) >= cutoffDate)
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      .map((item) => {
        const mainKey = ratioOptions[selectedRatio].mainKey as keyof typeof item;
        const value = item[mainKey] as number;
        return {
          ...item,
          [mainKey]: value <= 0 ? null : value,
        };
      });
  })();

  // Common axis styling for consistency
  const axisStyle = {
    tick: { fontSize: 12, fill: theme.palette.text.secondary },
    axisLine: { stroke: theme.palette.divider },
    tickLine: { stroke: 'transparent' },
  };

  // YAxis specific styling
  const yAxisStyle = {
    ...axisStyle,
    width: 35,
    dx: -5,
  };

  // Chart margin configuration
  const chartMargin = { top: 10, right: 100, left: 0, bottom: 25 };
  const chartMarginMobile = { top: 10, bottom: 20, left: 0 };

  // Enhanced tooltip formatter
  const tooltipFormatter = (value: number, name: string) => {
    // Only transform ratio values to N/A if they're zero or negative
    if (name === metricLabels[ratioOptions[selectedRatio].mainKey] && value <= 0) {
      return ['N/A', name];
    }
    const formattedValue = numeral(value).format('0,0.00');
    return [formattedValue, metricLabels[name] || name];
  };

  // Common chart props
  const commonChartProps = {
    syncId: 'companyValuation',
    margin: isMobile ? chartMarginMobile : chartMargin,
    animate: true,
  };

  // Common CartesianGrid props
  const gridProps = {
    strokeDasharray: '3 3',
    stroke: theme.palette.divider,
    opacity: 0.3,
  };

  // Get the mean value based on the selected year range
  const getMeanValue = () => {
    if (
      !historicalData ||
      !historicalData[selectedRatio] ||
      !historicalData[selectedRatio][timeFrame]
    ) {
      return 0;
    }

    const meanKey = `mean_${yearRange}y` as 'mean_5y' | 'mean_10y' | 'mean_20y';
    return historicalData[selectedRatio][timeFrame][meanKey] || 0;
  };

  // Get max value
  const getMaxValue = () => {
    if (
      !historicalData ||
      !historicalData[selectedRatio] ||
      !historicalData[selectedRatio][timeFrame] ||
      !historicalData[selectedRatio][timeFrame].ratios ||
      historicalData[selectedRatio][timeFrame].ratios.length === 0
    ) {
      return 0;
    }

    const yearsToInclude = yearRange;
    const latestDate = new Date(historicalData[selectedRatio][timeFrame].ratios[0].date);
    const cutoffDate = new Date(latestDate);
    cutoffDate.setFullYear(latestDate.getFullYear() - yearsToInclude);

    return Math.max(
      ...historicalData[selectedRatio][timeFrame].ratios
        // @ts-ignore
        .filter((r) => new Date(r.date) >= cutoffDate)
        // @ts-ignore
        .map((r) => r[ratioOptions[selectedRatio].mainKey as keyof typeof r] as number)
        // @ts-ignore
        .filter((val) => val > 0)
    );
  };

  // Get min value
  const getMinValue = () => {
    if (
      !historicalData ||
      !historicalData[selectedRatio] ||
      !historicalData[selectedRatio][timeFrame] ||
      !historicalData[selectedRatio][timeFrame].ratios ||
      historicalData[selectedRatio][timeFrame].ratios.length === 0
    ) {
      return 0;
    }

    const yearsToInclude = yearRange;
    const latestDate = new Date(historicalData[selectedRatio][timeFrame].ratios[0].date);
    const cutoffDate = new Date(latestDate);
    cutoffDate.setFullYear(latestDate.getFullYear() - yearsToInclude);

    return Math.min(
      ...historicalData[selectedRatio][timeFrame].ratios
        // @ts-ignore
        .filter((r) => new Date(r.date) >= cutoffDate)
        // @ts-ignore
        .map((r) => r[ratioOptions[selectedRatio].mainKey as keyof typeof r] as number)
        // @ts-ignore
        .filter((val) => val > 0)
    );
  };

  const renderValuationDescription = () => (
    <Typography variant="body2">
    {sortedData.length > 0 ? (
      <>
        The current <b>{ratioOptions[selectedRatio].displayName}</b> is{' '}
        <b>
          {typeof sortedData[sortedData.length - 1][
            ratioOptions[selectedRatio].mainKey as keyof (typeof sortedData)[0]
          ] === 'number' &&
          (sortedData[sortedData.length - 1][
            ratioOptions[selectedRatio].mainKey as keyof (typeof sortedData)[0]
          ] as number) <= 0
            ? 'N/A'
            : numeral(
                sortedData[sortedData.length - 1][
                  ratioOptions[selectedRatio].mainKey as keyof (typeof sortedData)[0]
                ]
              ).format('0,0.00')}
        </b>
        . It has been as high as{' '}
        {showPremiumOverlay ? (
          <Box component="span" sx={{ filter: 'blur(4px)' }}>
            <b>{numeral(getMaxValue()).format('0,0.00')}</b>
          </Box>
        ) : (
          <b>{numeral(getMaxValue()).format('0,0.00')}</b>
        )}{' '}
        and as low as{' '}
        {showPremiumOverlay ? (
          <Box component="span" sx={{ filter: 'blur(4px)' }}>
            <b>{numeral(getMinValue()).format('0,0.00')}</b>
          </Box>
        ) : (
          <b>{numeral(getMinValue()).format('0,0.00')}</b>
        )}{' '}
        in the last{' '}
        <b>{yearRange}</b> {timeFrame === 'annual' ? 'years' : 'quarters'}.
      </>
    ) : (
        'No historical data available for the selected period.'
      )}
    </Typography>
  );

  const renderMobile = () => {
    if (!sortedData.length)
      return (
        <Card sx={{ px: 1, my: 1 }}>
          <CardHeader
            title={
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="h6">Historical Valuation</Typography>
              </Stack>
            }
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary">
              No historical data available for the selected period.
            </Typography>
          </CardContent>
        </Card>
      );

    return (
      <Card sx={{ my: 1, position: 'relative' }}>
        <CardHeader
          title={
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h6">Historical Valuation</Typography>
            </Stack>
          }
          subheader={
            <Stack direction="row" spacing={1} alignItems="center">
              {renderValuationDescription()}
            </Stack>
          }
        />
        <CardContent>
          {/* Filter buttons */}
          <Box>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <ButtonBase
                  onClick={() => setSelectedRatio('pe')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: selectedRatio === 'pe' ? 'primary.light' : 'transparent',
                  }}
                >
                  P/E
                </ButtonBase>
                <ButtonBase
                  onClick={() => setSelectedRatio('pb')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: selectedRatio === 'pb' ? 'primary.light' : 'transparent',
                  }}
                >
                  P/B
                </ButtonBase>
                <ButtonBase
                  onClick={() => setSelectedRatio('ps')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: selectedRatio === 'ps' ? 'primary.light' : 'transparent',
                  }}
                >
                  P/S
                </ButtonBase>
                <ButtonBase
                  onClick={() => setSelectedRatio('pfcf')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: selectedRatio === 'pfcf' ? 'primary.light' : 'transparent',
                  }}
                >
                  P/FCF
                </ButtonBase>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <ButtonBase
                  onClick={() => handleTimeFrameChange('annual')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: timeFrame === 'annual' ? 'primary.light' : 'transparent',
                  }}
                >
                  Annual
                </ButtonBase>
                <ButtonBase
                  onClick={() => handleTimeFrameChange('quarterly')}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: timeFrame === 'quarterly' ? 'primary.light' : 'transparent',
                  }}
                >
                  Quarterly
                </ButtonBase>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <ButtonBase
                  onClick={() => handleYearRangeChange(5)}
                  sx={{
                    px: 1,
                    py: 0.5,
                    borderRadius: 1,
                    bgcolor: yearRange === 5 ? 'primary.light' : 'transparent',
                  }}
                >
                  5Y
                </ButtonBase>
                {timeFrame === 'annual' && (
                  <>
                    <ButtonBase
                      onClick={() => handleYearRangeChange(10)}
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor: yearRange === 10 ? 'primary.light' : 'transparent',
                      }}
                    >
                      10Y
                    </ButtonBase>
                    <ButtonBase
                      onClick={() => handleYearRangeChange(20)}
                      sx={{
                        px: 1,
                        py: 0.5,
                        borderRadius: 1,
                        bgcolor: yearRange === 20 ? 'primary.light' : 'transparent',
                      }}
                    >
                      20Y
                    </ButtonBase>
                  </>
                )}
              </Box>
            </Stack>
          </Box>

          {/* Charts */}
          <Box sx={{position: 'relative'}}>
          {/* Top Chart: Main Ratio as an Area Chart */}
            <Box sx={{filter: showPremiumOverlay ? 'blur(4px)' : 'none'}}>
            <Box sx={{ width: '100%', height: 250, mb: 3 }}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={sortedData} {...commonChartProps}>
                  <defs>
                    <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor={successLight} stopOpacity={0.8} />
                      <stop offset="95%" stopColor={successLight} stopOpacity={0.1} />
                    </linearGradient>
                  </defs>
                  <CartesianGrid {...gridProps} />
                  <XAxis
                    dataKey="date"
                    {...axisStyle}
                    height={40}
                    label={{
                      value: metricLabels[ratioOptions[selectedRatio].mainKey],
                      position: 'insideBottom',
                      offset: -15,
                      fill: theme.palette.text.secondary,
                      fontSize: 12,
                    }}
                  />
                  <YAxis {...yAxisStyle} />
                  <Tooltip
                    formatter={tooltipFormatter}
                    contentStyle={{
                      backgroundColor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      padding: '8px',
                      fontSize: 12,
                    }}
                    labelStyle={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                    }}
                  />
                  {getMeanValue() > 0 && (
                    <ReferenceLine
                      y={getMeanValue()}
                      stroke={theme.palette.grey[500]}
                      strokeWidth={2}
                      strokeDasharray="3 3"
                      ifOverflow="extendDomain"
                      isFront
                    >
                      <Label
                        value={`${yearRange}Y Avg: ${numeral(getMeanValue()).format('0,0.00')}`}
                        position="right"
                        fill={theme.palette.grey[700]}
                        fontSize={12}
                        fontWeight="bold"
                        style={{
                          backgroundColor: theme.palette.background.paper,
                          padding: '4px 8px',
                          border: `1px solid ${theme.palette.divider}`,
                          borderRadius: 2,
                        }}
                      />
                    </ReferenceLine>
                  )}
                  <Area
                    type="monotone"
                    dataKey={ratioOptions[selectedRatio].mainKey}
                    stroke={successLight}
                    strokeWidth={3}
                    fillOpacity={1}
                    fill="url(#colorGradient)"
                    connectNulls
                    dot
                    isAnimationActive={false}
                    activeDot={{ r: 4, fill: successLight }}
                  />
                </AreaChart>
              </ResponsiveContainer>
            </Box>

            {/* Middle Chart: Secondary Metric as a Bar Chart */}
            <Box sx={{ width: '100%', height: 200, mb: 3 }}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={sortedData} {...commonChartProps}>
                  <CartesianGrid {...gridProps} />
                  <XAxis
                    dataKey="date"
                    {...axisStyle}
                    height={40}
                    label={{
                      value: metricLabels[ratioOptions[selectedRatio].secondaryKey],
                      position: 'insideBottom',
                      offset: -15,
                      fill: theme.palette.text.secondary,
                      fontSize: 12,
                    }}
                  />
                  <YAxis {...yAxisStyle} />
                  <Tooltip
                    formatter={tooltipFormatter}
                    contentStyle={{
                      backgroundColor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      padding: '8px',
                      fontSize: 12,
                    }}
                    labelStyle={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                    }}
                  />
                  <Bar
                    dataKey={ratioOptions[selectedRatio].secondaryKey}
                    name={metricLabels[ratioOptions[selectedRatio].secondaryKey]}
                    radius={[4, 4, 0, 0]}
                    maxBarSize={50}
                  >
                    {sortedData.map((entry, index) => {
                      const value = entry[
                        ratioOptions[selectedRatio].secondaryKey as keyof typeof entry
                      ] as number;
                      return <Cell key={`cell-${index}`} fill={getBarColor(value)} />;
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </Box>

            {/* Bottom Chart: Price as a Line Chart */}
            <Box sx={{ width: '100%', height: 200 }}>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={sortedData} {...commonChartProps}>
                  <CartesianGrid {...gridProps} />
                  <XAxis
                    dataKey="date"
                    {...axisStyle}
                    height={40}
                    label={{
                      value: metricLabels.price,
                      position: 'insideBottom',
                      offset: -15,
                      fill: theme.palette.text.secondary,
                      fontSize: 12,
                    }}
                  />
                  <YAxis {...yAxisStyle} />
                  <Tooltip
                    formatter={tooltipFormatter}
                    contentStyle={{
                      backgroundColor: theme.palette.background.paper,
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 4,
                      padding: '8px',
                      fontSize: 12,
                    }}
                    labelStyle={{
                      color: theme.palette.text.primary,
                      fontSize: 12,
                    }}
                  />
                  <Line
                    type="monotone"
                    dataKey="price"
                    stroke={secondaryMain}
                    strokeWidth={2}
                    dot={false}
                    activeDot={{ r: 4, fill: secondaryMain }}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            </Box>
            {showPremiumOverlay && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  zIndex: 10,
                }}
              >
                <BecomePremiumToAccessContent ticker={ticker} premiumOnly />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    );
  };

  return isMobile ? (
    renderMobile()
  ) : (
    <Card sx={{ px: 2, my: 3, position: 'relative' }}>
      <CardHeader
        title={
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="h6">Historical Valuation</Typography>
          </Stack>
        }
        subheader={
          <Stack direction="row" spacing={1} alignItems="center">
            {renderValuationDescription()}
          </Stack>
        }
        action={
          <Stack spacing={1}>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <ButtonBase
                onClick={() => setSelectedRatio('pe')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: selectedRatio === 'pe' ? 'primary.light' : 'transparent',
                }}
              >
                P/E
              </ButtonBase>
              <ButtonBase
                onClick={() => setSelectedRatio('pb')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: selectedRatio === 'pb' ? 'primary.light' : 'transparent',
                }}
              >
                P/B
              </ButtonBase>
              <ButtonBase
                onClick={() => setSelectedRatio('ps')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: selectedRatio === 'ps' ? 'primary.light' : 'transparent',
                }}
              >
                P/S
              </ButtonBase>
              <ButtonBase
                onClick={() => setSelectedRatio('pfcf')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: selectedRatio === 'pfcf' ? 'primary.light' : 'transparent',
                }}
              >
                P/FCF
              </ButtonBase>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <ButtonBase
                onClick={() => handleTimeFrameChange('annual')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: timeFrame === 'annual' ? 'primary.light' : 'transparent',
                }}
              >
                Annual
              </ButtonBase>
              <ButtonBase
                onClick={() => handleTimeFrameChange('quarterly')}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: timeFrame === 'quarterly' ? 'primary.light' : 'transparent',
                }}
              >
                Quarterly
              </ButtonBase>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end' }}>
              <ButtonBase
                onClick={() => handleYearRangeChange(5)}
                sx={{
                  px: 1,
                  py: 0.5,
                  borderRadius: 1,
                  bgcolor: yearRange === 5 ? 'primary.light' : 'transparent',
                }}
              >
                5Y
              </ButtonBase>
              {timeFrame === 'annual' && (
                <>
                  <ButtonBase
                    onClick={() => handleYearRangeChange(10)}
                    sx={{
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: yearRange === 10 ? 'primary.light' : 'transparent',
                    }}
                  >
                    10Y
                  </ButtonBase>
                  <ButtonBase
                    onClick={() => handleYearRangeChange(20)}
                    sx={{
                      px: 1,
                      py: 0.5,
                      borderRadius: 1,
                      bgcolor: yearRange === 20 ? 'primary.light' : 'transparent',
                    }}
                  >
                    20Y
                  </ButtonBase>
                </>
              )}
            </Box>
          </Stack>
        }
      />

      <CardContent sx={{ position: 'relative' }}>
        <Box sx={{ filter: showPremiumOverlay ? 'blur(5px)' : 'none' }}>
          {/* Top Chart: Main Ratio as an Area Chart */}
          <Box sx={{ width: '100%', height: 250, mb: 3 }}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart data={sortedData} {...commonChartProps}>
                <defs>
                  <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor={successLight} stopOpacity={0.8} />
                    <stop offset="95%" stopColor={successLight} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
                <CartesianGrid {...gridProps} />
                <XAxis
                  dataKey="date"
                  {...axisStyle}
                  height={40}
                  label={{
                    value: metricLabels[ratioOptions[selectedRatio].mainKey],
                    position: 'insideBottom',
                    offset: -15,
                    fill: theme.palette.text.secondary,
                    fontSize: 12,
                  }}
                />
                <YAxis {...yAxisStyle} />
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 4,
                    padding: '8px',
                    fontSize: 12,
                  }}
                  labelStyle={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                  }}
                />
                {getMeanValue() > 0 && (
                  <ReferenceLine
                    y={getMeanValue()}
                    stroke={theme.palette.grey[500]}
                    strokeWidth={2}
                    strokeDasharray="3 3"
                    ifOverflow="extendDomain"
                    isFront
                  >
                    <Label
                      value={`${yearRange}Y Avg: ${numeral(getMeanValue()).format('0,0.00')}`}
                      position="right"
                      fill={theme.palette.grey[700]}
                      fontSize={12}
                      fontWeight="bold"
                      style={{
                        backgroundColor: theme.palette.background.paper,
                        padding: '4px 8px',
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: 2,
                      }}
                    />
                  </ReferenceLine>
                )}
                <Area
                  type="monotone"
                  dataKey={ratioOptions[selectedRatio].mainKey}
                  stroke={successLight}
                  strokeWidth={3}
                  fillOpacity={1}
                  fill="url(#colorGradient)"
                  connectNulls
                  dot
                  isAnimationActive={false}
                  activeDot={{ r: 4, fill: successLight }}
                />
              </AreaChart>
            </ResponsiveContainer>
          </Box>

          {/* Middle Chart: Secondary Metric as a Bar Chart */}
          <Box sx={{ width: '100%', height: 200, mb: 3 }}>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={sortedData} {...commonChartProps}>
                <CartesianGrid {...gridProps} />
                <XAxis
                  dataKey="date"
                  {...axisStyle}
                  height={40}
                  label={{
                    value: metricLabels[ratioOptions[selectedRatio].secondaryKey],
                    position: 'insideBottom',
                    offset: -15,
                    fill: theme.palette.text.secondary,
                    fontSize: 12,
                  }}
                />
                <YAxis {...yAxisStyle} />
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 4,
                    padding: '8px',
                    fontSize: 12,
                  }}
                  labelStyle={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                  }}
                />
                <Bar
                  dataKey={ratioOptions[selectedRatio].secondaryKey}
                  name={metricLabels[ratioOptions[selectedRatio].secondaryKey]}
                  radius={[4, 4, 0, 0]}
                  maxBarSize={50}
                >
                  {sortedData.map((entry, index) => {
                    const value = entry[
                      ratioOptions[selectedRatio].secondaryKey as keyof typeof entry
                    ] as number;
                    return <Cell key={`cell-${index}`} fill={getBarColor(value)} />;
                  })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </Box>

          {/* Bottom Chart: Price as a Line Chart */}
          <Box sx={{ width: '100%', height: 200 }}>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={sortedData} {...commonChartProps}>
                <CartesianGrid {...gridProps} />
                <XAxis
                  dataKey="date"
                  {...axisStyle}
                  height={40}
                  label={{
                    value: metricLabels.price,
                    position: 'insideBottom',
                    offset: -15,
                    fill: theme.palette.text.secondary,
                    fontSize: 12,
                  }}
                />
                <YAxis {...yAxisStyle} />
                <Tooltip
                  formatter={tooltipFormatter}
                  contentStyle={{
                    backgroundColor: theme.palette.background.paper,
                    border: `1px solid ${theme.palette.divider}`,
                    borderRadius: 4,
                    padding: '8px',
                    fontSize: 12,
                  }}
                  labelStyle={{
                    color: theme.palette.text.primary,
                    fontSize: 12,
                  }}
                />
                <Line
                  type="monotone"
                  dataKey="price"
                  stroke={secondaryMain}
                  strokeWidth={2}
                  dot={false}
                  activeDot={{ r: 4, fill: secondaryMain }}
                />
              </LineChart>
            </ResponsiveContainer>
          </Box>
        </Box>

        {showPremiumOverlay && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10,
            }}
          >
            <BecomePremiumToAccessContent ticker={ticker} premiumOnly />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CompanyValuationHistoricalChart2;
