import React, { useRef } from 'react';
import Box from '@mui/material/Box';
import { Typography, IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import html2canvas from 'html2canvas';
import { useAuthContext } from '../../auth/hooks';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884D8'];

export interface ChartData {
  label: string;

  [key: string]: any;
}

export interface ChartProps {
  chart: {
    chart_title: string;
    chart_data: ChartData[];
    chart_data_scale: string;
  };
}

/**
 * A container that adds a watermark in the bottom right corner.
 * Uses forwardRef so that it can be referenced by the export logic.
 */
interface WatermarkedContainerProps {
  children: React.ReactNode;
}

const WatermarkedContainer = React.forwardRef<HTMLDivElement, WatermarkedContainerProps>(
  ({ children }, ref) => (
    <div ref={ref} style={{ position: 'relative' }}>
      {children}
      <div
        style={{
          position: 'absolute',
          bottom: 5,
          right: 5,
          opacity: 0.5,
          fontSize: '12px',
          color: '#000',
          pointerEvents: 'none',
        }}
      >
        Powered by <strong>valuehunter.net</strong>
      </div>
    </div>
  )
);
WatermarkedContainer.displayName = 'WatermarkedContainer';

/**
 * Custom hook to export a DOM element to a PNG image.
 * Returns a ref for the container and an export function.
 */
function useExportImage(): [React.RefObject<HTMLDivElement>, () => void] {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleExport = () => {
    if (!containerRef.current) return;
    html2canvas(containerRef.current)
      .then((canvas) => {
        const pngDataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        link.href = pngDataUrl;
        link.download = 'valuehunter-chart.png';
        link.click();
      })
      .catch((err) => console.error('Error exporting chart:', err));
  };

  return [containerRef, handleExport];
}

export const RenderLineChart: React.FC<ChartProps> = ({ chart }) => {
  const [containerRef, handleExport] = useExportImage();
  const { user } = useAuthContext();

  if (!chart) return null;

  // Sort chart data alphabetically
  chart.chart_data.sort((a, b) => a.label.localeCompare(b.label));


  return (
    <Box sx={{ m: 5, position: 'relative' }}>
      <WatermarkedContainer ref={containerRef}>
        <Typography variant="h6" textAlign="center">
          {chart.chart_title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          (in {chart.chart_data_scale})
        </Typography>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart data={chart.chart_data} margin={{ bottom: 30, top: 30, right: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              interval={Math.ceil(chart.chart_data.length / 6)}
              tickFormatter={(label) =>
                label.length > 20 ? `${label.substring(0, 18)}...` : label
              }
              padding={{ left: 20, right: 20 }}
            />
            <YAxis />
            <Tooltip />
            <Legend />
            {Object.keys(chart.chart_data[0])
              .filter((key) => key !== 'label')
              .map((key, index) => (
                <Line
                  key={key}
                  type="linear"
                  dataKey={key}
                  stroke={COLORS[index % COLORS.length]}
                  strokeWidth={2}
                />
              ))}
          </LineChart>
        </ResponsiveContainer>
      </WatermarkedContainer>
      <IconButton
        onClick={handleExport}
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};

export const RenderBarChart: React.FC<ChartProps> = ({ chart }) => {
  const [containerRef, handleExport] = useExportImage();

  if (!chart) return null;

  // If label appears to be a date or year, sort the data
  if (chart.chart_data[0].label && chart.chart_data[0].label.startsWith('20')) {
    chart.chart_data.sort((a, b) => new Date(a.label).getTime() - new Date(b.label).getTime());
  }

  return (
    <Box sx={{ m: 5, position: 'relative' }}>
      <WatermarkedContainer ref={containerRef}>
        <Typography variant="h6" textAlign="center">
          {chart.chart_title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          (in {chart.chart_data_scale})
        </Typography>
        <ResponsiveContainer width="100%" height={350}>
          <BarChart data={chart.chart_data} margin={{ bottom: 30, top: 30, right: 50 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              interval={0}
              tickFormatter={(label) =>
                label.length > 20 ? `${label.substring(0, 18)}...` : label
              }
              angle={chart.chart_data.length > 5 ? 45 : 0}
              textAnchor={chart.chart_data.length > 5 ? 'start' : 'middle'}
            />
            <YAxis />
            <Tooltip />
            <Legend verticalAlign="bottom" align="center" />
            {Object.keys(chart.chart_data[0])
              .filter((key) => key !== 'label')
              .map((key, index) => (
                <Bar
                  key={key}
                  dataKey={key}
                  fill={COLORS[index % COLORS.length]}
                  radius={[5, 5, 0, 0]}
                />
              ))}
          </BarChart>
        </ResponsiveContainer>
      </WatermarkedContainer>
      <IconButton
        onClick={handleExport}
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};

export const RenderPieChart: React.FC<ChartProps> = ({ chart }) => {
  const [containerRef, handleExport] = useExportImage();

  if (!chart || !chart.chart_data) return null;

  // Calculate total for percentage values
  const total = chart.chart_data.reduce((sum, entry) => sum + (entry.data || 0), 0);
  const enrichedData = chart.chart_data.map((entry) => ({
    ...entry,
    percentage: total > 0 ? (entry.data / total) * 100 : 0,
  }));

  // Custom label to display percentages inside the pie chart
  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize={12}>
        {`${percent * 100 > 1 ? (percent * 100).toFixed(1) : '<1'}%`}
      </text>
    );
  };

  return (
    <Box sx={{ m: 5, position: 'relative' }}>
      <WatermarkedContainer ref={containerRef}>
        <Typography variant="h6" textAlign="center">
          {chart.chart_title}
        </Typography>
        <Typography variant="body2" textAlign="center">
          (in {chart.chart_data_scale})
        </Typography>
        <ResponsiveContainer width="100%" height={350}>
          <PieChart margin={{ bottom: 30, top: 30, right: 50 }}>
            <Pie
              data={enrichedData}
              dataKey="data"
              nameKey="label"
              cx="50%"
              cy="50%"
              innerRadius={60} // <-- Add an inner radius to create a donut
              outerRadius={120}
              paddingAngle={2} // <-- Slight spacing between slices
              cornerRadius={5} // <-- Rounded corners
              stroke="#ffffff" // <-- White stroke
              fill="#8884d8"
              label={renderCustomizedLabel}
              labelLine={false}
            >
              {enrichedData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip
              formatter={(value, name, entry) => [
                `$${value.toLocaleString()} (${entry.payload.percentage.toFixed(1)}%)`,
                name,
              ]}
            />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </WatermarkedContainer>
      <IconButton
        onClick={handleExport}
        sx={{
          position: 'absolute',
          top: 5,
          right: 5,
          '&:hover': { backgroundColor: 'rgba(255,255,255,0.9)' },
        }}
      >
        <DownloadIcon />
      </IconButton>
    </Box>
  );
};
