export interface ScoredCompany1 {
  ticker: string;
  company_name: string;
  sector: string;
  industry: string;
  market_cap: number;
  image_url: string;
  score?: number;

}

export interface ScoredCompany2 extends ScoredCompany1 {
  price: number;
  prices: HistoricalPrice[];
  description: string;
  score_data?: ScoreData;
  score_updated: string;
  country: string;
  currency: string;
  website: string;
  full_time_employees: number;
  ceo: string;
  quick_numbers: QuickNumbers;
}

export interface EarningsCallTranscript {
  ticker: string;
  year: number;
  quarter: number;
  content: string;
}

export interface CompanyDocumentsType {
  earnings_call_transcript: EarningsCallTranscript | null;
  latest_annual_url: string | null;
  latest_quarterly_url: string | null;
}

export interface QuickNumbers {
  valuation: QuickNumbersValuation;
  solvency: QuickNumbersSolvency;
  efficiency: QuickNumbersEfficiency;
  growth: QuickNumbersGrowth;
  dividend_yield_percentage: number;
}

export interface QuickNumbersValuation {
  price_earnings_ratio: number;
  price_to_book_ratio: number;
  price_to_sales_ratio: number;
  price_to_free_cash_flows_ratio: number;
  price_to_operating_cash_flows_ratio: number;
  ev_to_ebitda: number;
  price_to_earnings_growth_ratio: number;
}

export interface QuickNumbersSolvency {
  debt_to_equity_ratio: number;
  interest_coverage_ratio: number;
  cashflow_to_debt_ratio: number;
  debt_ratio: number;
  long_term_debt_to_capitalization_ratio: number;
  current_ratio: number;
  cash_ratio: number;
}

export interface QuickNumbersEfficiency {
  return_on_equity_ratio: number;
  return_on_capital_employed: number;
  gross_profit_margin: number;
  net_profit_margin: number;
  asset_turnover_ratio: number;
  inventory_turnover_ratio: number;
  receivables_turnover_ratio: number;
}

export interface QuickNumbersGrowth {
  revenue_growth: number;
  net_income_growth: number;
  operating_cashflow_growth: number;
  five_y_revenue_growth: number;
  five_y_net_income_growth: number;
  expected_revenue_growth_quarter: number;
  expected_revenue_growth_year: number;
}

export interface ScoreData {
  price_data: PriceData;
  solvency_data: SolvencyData;
  efficiency_data: EfficiencyData;
  growth_data: GrowthData;
}

export interface PriceData {
  score: number;
  price_to_earnings: number;
  price_to_book: number;
  price_to_free_cashflow: number;
  ev_to_ebitda: number;
  peg: number;
}

export interface SolvencyData {
  score: number;
  current_ratio: number;
  quick_ratio: number;
  debt_to_equity: number;
  debt_to_assets: number;
  interest_coverage_ratio: number;
  piotroski_score: number;
}

export interface EfficiencyData {
  score: number;
  return_on_capital_employed: number;
  return_on_equity: number;
  return_on_assets: number;
  gross_profit_margin: number;
  net_profit_margin: number;
  asset_turnover: number;
}

export interface GrowthData {
  score: number;
  revenue_1y_expected_change: number;
  ebitda_1y_expected_change: number;
  eps_1y_expected_change: number;
  revenue_5y_actual_change: number;
  ebitda_5y_actual_change: number;
  eps_5y_actual_change: number;
  revenue_growth_consistency: number;
  ebitda_growth_consistency: number;
  eps_growth_consistency: number;
}

export interface HistoricalPrice {
  date: string;
  close: number;
}

interface FinancialsForPeriod {
  balance_sheet: {
    date: string,
    totalAssets: number,
    totalCurrentAssets: number,
    totalNonCurrentAssets: number,
    goodwillAndIntangibleAssets: number,
    totalLiabilities: number,
    totalCurrentLiabilities: number,
    totalNonCurrentLiabilities: number,
    retainedEarnings: number,
    totalEquity: number,
    totalStockholdersEquity: number,
    commonStock: number,
    shortTermInvestments: number,
    longTermInvestments: number,
    totalInvestments: number,
    shortTermDebt: number,
    longTermDebt: number,
    netDebt: number,
    totalDebt: number,
  }[],
  income_statement: {
    date: string,
    revenue: number,
    netIncome: number,
    operatingIncome: number,
    operatingExpenses: number,
    operatingIncomeRatio: number,
    ebitda: number,
    eps: number,
  }[],
  cash_flow_statement: {
    date: string,
    netIncome: number,
    freeCashFlow: number,
    operatingCashFlow: number,
    netCashProvidedByOperatingActivities: number,
    cashAtEndOfPeriod: number,
    capitalExpenditure: number,
    netCashUsedForInvestingActivities: number,
    depreciationAndAmortization: number,
    debtRepayment: number,
    commonStockRepurchased: number,
    dividendsPaid: number,
    inventory: number,
    accountsReceivables: number,
    stockBasedCompensation: number,
    accountsPayables: number,
    deferredIncomeTax: number,
    otherNonCashItems: number,
    otherWorkingCapital: number,
  }[]
}

export interface Financials {
  annual: FinancialsForPeriod,
  quarterly: FinancialsForPeriod
}

export interface FinancialsRow {
  label: string,
  periods_values: number[]
  periods: string[],
}

export interface ChartSeriesType {
  type: string,
  data: {
    name: string,
    data: number[]
  }[]
}

export interface AIAnalysisType {
  type: string
  datetime_generated: string
  answer: string
  cost_in_credits: number
}

export enum AIAnalysisTypesEnum {
  Valuation = "valuation",
  Solvency = "solvency",
  Efficiency = "efficiency",
  QuickNumbers = "quick_numbers",
  AnnualFinancials = "annual_financials",
  BusinessModel = "business_model",
  CompetitiveAdvantage = "competitive_advantage",
  Risks = "risks",
  FutureOutlook = "future_outlook",
  ShareRepurchase = "share_repurchase",
}
