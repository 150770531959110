import React from 'react';
import { Box, TableRow, TableCell, Typography, Tooltip, Stack, Grid, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ScoreBreakdownProgressElement from 'src/components/company/score-breakdown-progress-element';
import { marketCapFormatter } from '../../utils/formatting';
import CompanyLogo, { defaultGrowthScore } from '../../components/company/constants';
import CustomTable from '../../components/utility_components/custom-table';
import Iconify from '../../components/iconify';

// Data types for incoming company objects.
export interface ScoredCompany {
  ticker: string;
  company_name: string;
  image_url: string;
  sector: string;
  country: string;
  market_cap: number;
  score: number;
  score_data: {
    price_data?: { score: number };
    solvency_data?: { score: number };
    efficiency_data?: { score: number };
    growth_data?: { score: number };
  };
  magic_formula: {
    rank: number;
    magic_formula_score: number;
    return_on_capital: number;
    earnings_yield: number;
    return_on_capital_rank: number;
    earnings_yield_rank: number;
  };
}

// Data type for the transformed data used by CustomTable.
export interface CompanyRawData {
  ticker: string;
  company_name: string;
  sector: string;
  market_cap: number;
  country: string;
  score: number;
  valuation: number | undefined;
  financial_strength: number | undefined;
  efficiency: number | undefined;
  growth: number;
  logo: string;
  score_data: any;
}

// Props for the CompanyTable component.
export interface CompanyTableProps {
  companies: ScoredCompany[];
  onCompanyClick: (ticker: string, newTab: boolean) => void;
}

const tableFontSize = '0.8rem';
const tableFontSizeSmall = '0.7rem';

export default function CompanyTable({
  companies,
  onCompanyClick,
}: CompanyTableProps): JSX.Element {
  const theme = useTheme();
  const navigate = useNavigate();

  // Convert the incoming companies into the shape expected by CustomTable.
  const prepareRawData = (companies_: ScoredCompany[]): CompanyRawData[] =>
    companies_.map((company) => ({
      ticker: company.ticker,
      company_name: company.company_name,
      sector: company.sector,
      market_cap: company.market_cap,
      country: company.country,
      score: company.score,
      valuation: company.score_data?.price_data?.score,
      financial_strength: company.score_data?.solvency_data?.score,
      efficiency: company.score_data?.efficiency_data?.score,
      growth: company.score_data?.growth_data
        ? company.score_data.growth_data.score
        : defaultGrowthScore,
      logo: company.image_url,
      score_data: company.score_data,
    }));

  // Handle row click: open in new tab if Ctrl/Cmd or middle mouse is used.
  const handleRowClick = (row: CompanyRawData, event: React.MouseEvent<HTMLElement>): void => {
    if (event.ctrlKey || event.metaKey || event.button === 1) {
      onCompanyClick(row.ticker, true);
    } else {
      onCompanyClick(row.ticker, false);
    }
  };

  // Desktop row renderer.
  const renderRow = (row: CompanyRawData, index: number): JSX.Element => (
    <TableRow
      key={index}
      onClick={(event) => handleRowClick(row, event)}
      sx={{
        maxHeight: '50px',
        whiteSpace: 'nowrap',
        '&:last-child td, &:last-child th': { border: 0 },
        '&:hover': {
          cursor: 'pointer',
          backgroundColor: theme.palette.action.hover,
        },
      }}
    >
      <TableCell>
        <CompanyLogo ticker={row.ticker} logo_url={row.logo} />
      </TableCell>
      <TableCell>
        <Typography sx={{ color: 'text.secondary', fontSize: tableFontSize }}>
          {row.ticker}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          sx={{
            display: 'inline-block',
            textAlign: 'center',
            fontSize: tableFontSize,
            maxWidth: 200,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {row.company_name}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={{ color: 'text.secondary', fontSize: tableFontSizeSmall }}>
          {row.sector}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography sx={{ fontSize: tableFontSize, fontWeight: 'bold' }}>
          ${marketCapFormatter(row.market_cap)}
        </Typography>
      </TableCell>
      <TableCell>
        <Tooltip title={row.country}>
          <Box component="span">
            <Iconify
              icon={`flagpack:${row.country?.toLowerCase()}`}
              sx={{ borderRadius: 0.65, width: 28 }}
            />
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell align="center">
        <Typography sx={{ fontSize: tableFontSize, fontWeight: 'bold' }}>
          {row.score.toFixed(2)}
        </Typography>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={1}>
          <ScoreBreakdownProgressElement
            score={row.valuation || 0}
            widthParam="50px"
            heightParam="8px"
          />
          <Typography sx={{ fontSize: tableFontSizeSmall }}>
            {row.valuation !== undefined ? row.valuation.toFixed(2) : 'N/A'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={1}>
          <ScoreBreakdownProgressElement
            score={row.financial_strength || 0}
            widthParam="50px"
            heightParam="8px"
          />
          <Typography sx={{ fontSize: tableFontSizeSmall }}>
            {row.financial_strength !== undefined ? row.financial_strength.toFixed(2) : 'N/A'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={1}>
          <ScoreBreakdownProgressElement
            score={row.efficiency || 0}
            widthParam="50px"
            heightParam="8px"
          />
          <Typography sx={{ fontSize: tableFontSizeSmall }}>
            {row.efficiency !== undefined ? row.efficiency.toFixed(2) : 'N/A'}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell>
        <Stack direction="column" spacing={1}>
          <ScoreBreakdownProgressElement score={row.growth} widthParam="50px" heightParam="8px" />
          <Typography sx={{ fontSize: tableFontSizeSmall }}>
            {row.growth ? row.growth.toFixed(2) : 'N/A'}
          </Typography>
        </Stack>
      </TableCell>
    </TableRow>
  );

  // Mobile row renderer: This version is similar to your provided renderRowMobile code,
  // but without the price, price target consensus, and price target difference sections.
  const renderRowMobile = (row: CompanyRawData, index: number): JSX.Element => (
    <Box
      key={index}
      onClick={() => navigate(`/companies/${row.ticker}`)}
      sx={{
        backgroundColor: index % 2 === 0 ? theme.palette.background.default : theme.palette.action.selected,
        p: 1,
      }}
    >
      <Stack direction="column">
        {/* Logo, Company Name, and Ticker with an "Open" button */}
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Stack direction="row">
            <CompanyLogo ticker={row.ticker} logo_url={row.logo} />
            <Stack direction="column" sx={{ ml: 1 }}>
              <Typography sx={{ fontSize: tableFontSize, fontWeight: 'bold' }}>
                {row.company_name}
              </Typography>
              <Typography sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
                {row.ticker}
              </Typography>
            </Stack>
          </Stack>
          <Box>
            <Button
              color="inherit"
              variant="soft"
              onClick={() => navigate(`/companies/${row.ticker}`)}
              endIcon={<Iconify icon="eva:arrow-ios-forward-fill" width={20} />}
            >
              Open
            </Button>
          </Box>
        </Stack>
        {/* Details */}
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
              Country
            </Typography>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <Iconify icon={`flagpack:${row.country.toLowerCase()}`} sx={{ borderRadius: 0.65, width: 16 }} />
              <Typography variant="body2" sx={{ fontSize: tableFontSize }}>
                {row.country}
              </Typography>
            </Stack>
            <Typography variant="body2" sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
              Sector
            </Typography>
            <Typography variant="body2" sx={{ fontSize: tableFontSize }}>
              {row.sector}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
              Market Cap
            </Typography>
            <Typography variant="body2" sx={{ fontSize: tableFontSize }}>
              ${marketCapFormatter(row.market_cap)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2" sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
              Overall Score
            </Typography>
            <Typography variant="body2" fontWeight="bold" sx={{ fontSize: tableFontSize }}>
              {row.score.toFixed(2)}
            </Typography>
            <Typography variant="body2" sx={{ fontSize: tableFontSize, color: 'text.secondary' }}>
              Breakdown
            </Typography>
            <Stack direction="column" spacing={0.1}>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{ fontSize: 10 }}>V</Typography>
                <ScoreBreakdownProgressElement
                  score={row.score_data?.price_data?.score}
                  heightParam="8px"
                  widthParam="40px"
                  sx={{ mx: 0.2 }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{ fontSize: 10 }}>F</Typography>
                <ScoreBreakdownProgressElement
                  score={row.score_data?.solvency_data?.score}
                  heightParam="8px"
                  widthParam="40px"
                  sx={{ mx: 0.2 }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{ fontSize: 10 }}>E</Typography>
                <ScoreBreakdownProgressElement
                  score={row.score_data?.efficiency_data?.score}
                  heightParam="8px"
                  widthParam="40px"
                  sx={{ mx: 0.2 }}
                />
              </Stack>
              <Stack direction="row" alignItems="center" spacing={0.5}>
                <Typography sx={{ fontSize: 10 }}>G</Typography>
                <ScoreBreakdownProgressElement
                  score={row.score_data?.growth_data?.score}
                  heightParam="8px"
                  widthParam="40px"
                  sx={{ mx: 0.2 }}
                />
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );

  // Define headers for CustomTable.
  const headers = [
    { display: '', key: 'logo' },
    { display: 'Ticker', key: 'ticker' },
    { display: 'Company Name', key: 'company_name' },
    { display: 'Sector', key: 'sector' },
    { display: 'Market Cap', key: 'market_cap' },
    { display: 'Country', key: 'country' },
    { display: 'Score', key: 'score' },
    { display: 'Valuation', key: 'valuation' },
    { display: 'Financial Strength', key: 'financial_strength' },
    { display: 'Efficiency', key: 'efficiency' },
    { display: 'Growth', key: 'growth' },
  ];

  return (
    <CustomTable
      headers={headers}
      rawData={prepareRawData(companies)}
      renderRow={renderRow}
      renderRowMobile={renderRowMobile}
      rowsPerPage={10}
      initialSortConfig={{ column: 'score', order: 'desc' }}
    />
  );
}