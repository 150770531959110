import React, {useEffect} from "react";
import {Box, useMediaQuery} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import GeneralNewsAnalysis from "./general-news-analysis";
import Iconify from "../iconify";
import axios, {endpoints} from "../../utils/axios";
import {useAuthContext} from "../../auth/hooks";
import { LoginToAccess } from "../utility_components/login-to-access";
import GeneratedOn from "../utility_components/generated-on";
import {gaTimingEvent} from "../../utils/ga";

interface GeneralNewsAllTabsProps {
  markets: string[];
}

export default function GeneralNewsAllTabs({ markets }: GeneralNewsAllTabsProps) {
  const [value, setValue] = React.useState('1');
  const [newsAnalyses, setNewsAnalyses] = React.useState({});
  const [dateGenerated, setDateGenerated] = React.useState('');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const {authenticated} = useAuthContext();

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // track time spent
  useEffect(() => {
    const startTime = Date.now();

    return () => {
      const endTime = Date.now();
      const timeSpentMs = endTime - startTime;
      const timeSpentSec = Math.round(timeSpentMs / 1000);
      gaTimingEvent("Markets Overview", timeSpentSec)
    };
  }, []);

  // fetch all news analyses
  useEffect(() => {
    if (authenticated) {
      markets.forEach(market => {
        axios.get<any>(`${endpoints.ai_analysis_news}?market=${market}`)
          .then(analysisResponse => {
            const parsedAnalysis = JSON.parse(analysisResponse.data.answer);
            parsedAnalysis.themes = parsedAnalysis.themes.sort((a: any, b: any) => a.label.localeCompare(b.label));
            setNewsAnalyses(prevState => ({...prevState, [market]: parsedAnalysis}));
            setDateGenerated(analysisResponse.data.datetime_generated);
          })
          .catch(err => {
            console.error(err);
          })
      })
    }
  }, [authenticated, markets]);

  const getMarketIcon = (market: string) => {
    switch (market) {
      case 'us':
        return <Iconify icon="flagpack:us" sx={{borderRadius: 0.65, width: 28}}/>;
      case 'eu':
        return <Iconify icon="flag:eu-4x3" sx={{borderRadius: 0.65, width: 28}}/>;
      case 'uk':
        return <Iconify icon="flagpack:gb-ukm" sx={{borderRadius: 0.65, width: 28}}/>;
      case 'china':
        return <Iconify icon="flagpack:cn" sx={{borderRadius: 0.65, width: 28}}/>;
      default:
        return <Iconify icon="flagpack:us" sx={{borderRadius: 0.65, width: 28}}/>;
    }
  };

  const renderTabs = () => (
    <Box sx={{width: '100%', typography: 'body1', mt: 3}}>
      <TabContext value={value}>
        {markets.length > 1 && (
          <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%'}}>
            <TabList onChange={handleChange}>
              {markets.map((market, index) => (
                <Tab
                  key={market}
                  icon={getMarketIcon(market)}
                  label={market.toUpperCase()}
                  value={(index + 1).toString()}
                />
              ))}
            </TabList>
          </Box>
        )}
        <Box sx={{ml: 3}}>
          <GeneratedOn dateToDisplay={dateGenerated} textVariant="caption" textPrefix="Last updated" timeAgo/>
        </Box>
        {markets.map((market, index) => (
          <TabPanel key={market} value={(index + 1).toString()} sx={{p: 1}}>
            {/* @ts-ignore */}
            <GeneralNewsAnalysis newsAnalysis={newsAnalyses[market]}
                               maxHeight={isMobile ? "100%" : "700px"}/>
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );

  return (
    <>
      <Typography variant="h5" sx={{mt: 2}}>Latest News Summary</Typography>
      <Typography variant="body2" sx={{color: "text.secondary"}}>Summary of the latest
        news grouped by economy and topic. Click on <b>{`Show News  `}</b> to see the actual articles
        for each topic.</Typography>

      <LoginToAccess returnTo="/dashboard#markets-overview" featureDescription="markets overview" >
        {renderTabs()}
      </LoginToAccess>
    </>
  );
}
