import React from 'react';
import {useTheme} from '@mui/material/styles';
import {Box, Stack, Typography} from '@mui/material';
import {Bar, BarChart, CartesianGrid, Cell, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis,} from 'recharts';
import numeral from 'numeral';
import HelpIcon from "../utility_components/help-icon";
import {defaultFreeCashFlowData} from "./company-valuation-default-data";
import {isPremiumUser} from "./utils";
import {useAuthContext} from "../../auth/hooks";
import {BecomePremiumToAccessContent} from "../utility_components/become-premium-to-access-content";
import PremiumOnlyLabel from '../premium-only-label/premium-only-label';

interface SeriesData {
  year: string;
  value: number;
}

export interface ValuationFCFData {
  actual: SeriesData[];
  actualChangePercentage: number;
  predicted: SeriesData[];
  predictedChangePercentage: number;
}

const CompanyValuationFCFChart: React.FC<ValuationFCFData> = ({
                                                                actual,
                                                                actualChangePercentage,
                                                                predicted,
                                                                predictedChangePercentage,
                                                              }) => {
  const theme = useTheme();
  const {authenticated, user} = useAuthContext();
  const successLight = theme.palette.primary.light;
  const [premiumDialogOpen, setPremiumDialogOpen] = React.useState(false);

  // Combine actual and predicted data
  const combinedData = [...actual, ...predicted].map(({year, value}) => ({
    year, // Use 'year' as the key for the x-axis
    value,
  }));

  // Determine the index where predicted data starts
  const predictedStartIndex = actual.length;

  // Custom tooltip formatter
  const tooltipFormatter = (value: number) => numeral(value).format('0.00a');
  const isPremium = isPremiumUser(user)
  const blurred = (!authenticated || !isPremium);
  if (blurred) {
    actual = defaultFreeCashFlowData.actual;
    actualChangePercentage = defaultFreeCashFlowData.actualChangePercentage;
    predicted = defaultFreeCashFlowData.predicted;
    predictedChangePercentage = defaultFreeCashFlowData.predictedChangePercentage;
  }

  if (actual.length === 0 || predicted.length === 0) {
    return (
      <Box sx={{mb: 3}}>
        <Typography variant="h6" sx={{color: 'text.primary'}}>
          Cash Flow Forecast
        </Typography>
        <Typography variant="body2" sx={{color: 'text.secondary'}}>
          Cash Flow Forecast is not available for this company.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{px: 2, my: 3, position: "relative"}}>
      <Box className="chart-titles" sx={{px: 1, my: 2}}>
        <Stack direction="row" spacing={1}>
          <Typography variant="h6">
            Free Cashflow Forecast
          </Typography>
          <HelpIcon text='The freecash flow growth forecast is based on previous revenue growth.'/>
          <PremiumOnlyLabel/>
        </Stack>
        <Typography variant="body2" component="span" color="text.secondary">
          <b>Free Cashflow</b> is forecasted to{' '}
          {predictedChangePercentage >= 0 ? 'grow' : 'decline'} by{' '}
          <b style={{filter: blurred ? 'blur(4px)' : 'none'}}>{predictedChangePercentage.toFixed(1)}%</b> per
          year for the next <b style={{filter: blurred ? 'blur(4px)' : 'none'}}>{predicted.length}</b> years.
        </Typography>
      </Box>
      <ResponsiveContainer width="100%" height={394}>
        <BarChart data={combinedData} style={{
          cursor: blurred ? 'pointer' : 'default',
          filter: blurred ? 'blur(4px)' : 'none',
        }}>
          {/* Define SVG patterns */}
          <defs>
            <pattern
              id="predictedPattern"
              patternUnits="userSpaceOnUse"
              width={6}
              height={6}
              patternTransform="rotate(45)"
            >
              <line
                x1="0"
                y="0"
                x2="0"
                y2="6"
                style={{stroke: successLight, strokeWidth: 5}}
              />
            </pattern>
          </defs>
          <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" opacity={0.5}/>
          <XAxis
            dataKey="year"
            tick={{fontSize: 12, fill: theme.palette.text.secondary}}
            axisLine={{stroke: "transparent"}}
            tickLine={{stroke: "transparent"}}
          />
          <YAxis
            tick={{fontSize: 12, fill: theme.palette.text.secondary}}
            tickFormatter={(tick) => numeral(tick).format('0.00a')}
            axisLine={{stroke: "transparent"}}
            tickLine={{stroke: "transparent"}}
          />
          <Tooltip
            formatter={tooltipFormatter}
            labelFormatter={(label) => label}
          />

          <Bar dataKey="value" name="Free Cashflow" radius={[5, 5, 0, 0]}>
            {combinedData.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={
                  index < predictedStartIndex
                    ? successLight
                    : 'url(#predictedPattern)'
                }
              />
            ))}
          </Bar>
          <Legend
            payload={[
              {
                value: 'Previous Free Cashflow',
                type: 'circle',
                color: successLight,
              },
              {
                value: 'Predicted Free Cashflow',
                type: 'circle',
                color: `url(#predictedPattern)`,
              },
            ]}
            wrapperStyle={{fontSize: '12px'}}
          />
        </BarChart>
      </ResponsiveContainer>

      {!isPremium && (
        <Box sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <BecomePremiumToAccessContent ticker={undefined} premiumOnly/>
        </Box>
      )}
    </Box>
  );
};

export default CompanyValuationFCFChart;
