import React, {useEffect, useState} from "react";
import {Box, Skeleton} from "@mui/material";
import {useTheme} from "@mui/material/styles";

import axios, {endpoints} from "../../utils/axios";
import {NewsAnalysisType} from "./news_news";
import {customScrollbarStyles} from "../../theme/css";
import GeneratedOn from "../utility_components/generated-on";
import {useCredits} from "../../providers/credits-provider";
import NewsThemes from "./news_themes";

interface CompanyDocumentsProps {
  ticker: string | undefined;
  setAiAnalysesReady?: (key: string) => void | undefined;
  renderCompact?: boolean;
}

export default function NewsAnalysis({
                                     ticker,
                                     setAiAnalysesReady,
                                     renderCompact = false
                                   }: CompanyDocumentsProps) {
  const [isLoadingAnalysis, setIsLoadingAnalysis] = useState(false);
  const [isLoadingAnalysisLatest, setIsLoadingAnalysisLatest] = useState(false);
  const [newsAnalysis, setNewsAnalysis] = useState<NewsAnalysisType>({
    themes: [],
    news: [],
  });
  const [analysisDatetimeGenerated, setAnalysisDatetimeGenerated] = useState<string>('');
  const [themeIdToShowNews, setThemeIdToShowNews] = useState<boolean[]>([]);
  const {refreshCredits} = useCredits();

  const theme = useTheme();

  useEffect(() => {
      const handleResponse = (analysisResp: any) => {
        const parsedAnalysis = JSON.parse(analysisResp.data.answer);

        // sort themes by number of news
        parsedAnalysis.themes = parsedAnalysis.themes.sort((a: any, b: any) => b.news_ids.length - a.news_ids.length);
        setNewsAnalysis(parsedAnalysis);
        setAnalysisDatetimeGenerated(analysisResp.data.datetime_generated);
        setThemeIdToShowNews(new Array(parsedAnalysis.themes.length).fill(false));
      }
      const getNewsAnalysis = async () => {
        try {
          // fetch existing analysis
          setIsLoadingAnalysis(true);
          const analysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=news`);
          handleResponse(analysisResponse);
          setIsLoadingAnalysis(false);

          if (!analysisResponse.data.is_latest) {
            // Fetch latest analysis
            setIsLoadingAnalysisLatest(true);
            const latestAnalysisResponse = await axios.post<any>(`${endpoints.ai_analysis}/${ticker}?analysis_type=news&return_latest=true`);
            handleResponse(latestAnalysisResponse);
            setIsLoadingAnalysisLatest(false);
          }

        } catch (error) {
          console.error("Error fetching company news AI analysis:", error);
        } finally {
          if (setAiAnalysesReady) {
            setAiAnalysesReady('news');
          }
          refreshCredits();
        }
        setIsLoadingAnalysis(false);
        setIsLoadingAnalysisLatest(false);
      }

      getNewsAnalysis();
    },
    [ticker, refreshCredits, setAiAnalysesReady]
  );

  if (isLoadingAnalysis) {
    return (
      <>
        <Skeleton variant="text" height={50}/>
        <Skeleton variant="text" height={50}/>
        <Skeleton height={500}/>
      </>
    )
  }

  return (
    <Box
      sx={{
        maxHeight: '600px',
        overflowY: 'auto',
        mb: 1,
        ...customScrollbarStyles(theme)
      }}
    >
      <GeneratedOn dateToDisplay={analysisDatetimeGenerated} textVariant="caption" textPrefix="generated on" regenerating={isLoadingAnalysisLatest}/>

      <NewsThemes 
        newsAnalysis={newsAnalysis}
        themeIdToShowNews={themeIdToShowNews}
        setThemeIdToShowNews={setThemeIdToShowNews}
        renderCompact={renderCompact}
      />
    </Box>
  )
}
