// @mui
import React, { useCallback, useEffect, useState } from 'react';
import { Box, Card, Typography, MenuItem, ButtonBase, CardContent } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';

// Recharts imports
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Cell,
  ReferenceLine,
  LabelList,
  Label,
} from 'recharts';
import numeral from 'numeral';

import CustomPopover, { usePopover } from '../custom-popover';
import Iconify from '../iconify';

// Define allowed keys for the peers data
export type PeerDataKey =
  | 'priceToEarnings'
  | 'priceToBook'
  | 'priceToSales'
  | 'priceToFreeCashFlows'
  | 'priceEarningsToGrowth';

// Extend the Peer interface to include the PEG ratio
interface Peer {
  ticker: string;
  companyName: string;
  sector: string;
  industry: string;
  priceToEarnings: number;
  priceToBook: number;
  priceToSales: number;
  priceToFreeCashFlows: number;
  priceEarningsToGrowth: number;
}

export interface PeersData {
  priceToEarnings: {
    mean: number;
    peers: Peer[];
  };
  priceToBook: {
    mean: number;
    peers: Peer[];
  };
  priceToSales: {
    mean: number;
    peers: Peer[];
  };
  priceToFreeCashFlows: {
    mean: number;
    peers: Peer[];
  };
  priceEarningsToGrowth: {
    mean: number;
    peers: Peer[];
  };
}

export interface PeersProps {
  ticker: string | undefined;
  peersData: PeersData;
}

interface ChartData {
  ticker: string;
  value: number;
  companyName: string;
}

export default function CompanyValuationPeersComparison({ ticker, peersData }: PeersProps) {
  const theme = useTheme();
  const primaryLight = theme.palette.primary.light;
  const primaryMain = theme.palette.primary.main;

  const popover = usePopover();
  const [currentSeriesKey, setCurrentSeriesKey] = useState('P/E Ratio');
  const [currentSeriesField, setCurrentSeriesField] = useState<PeerDataKey>('priceToEarnings');
  const [currentSeriesValue, setCurrentSeriesValue] = useState<number | undefined>(
    peersData.priceToEarnings.peers.find((peer) => peer.ticker === ticker)?.priceToEarnings
  );
  console.log('currentSeriesValue: ', currentSeriesValue);
  console.log('currentMean: ', peersData.priceToEarnings.mean);
  const [currentMean, setCurrentMean] = useState(peersData.priceToEarnings.mean);
  const [barsColors, setBarsColors] = useState<string[]>(
    peersData.priceToEarnings.peers.map((peer) =>
      peer.ticker === ticker ? primaryMain : primaryLight
    )
  );

  useEffect(() => {
    setCurrentMean(peersData.priceToEarnings.mean);
  }, [peersData.priceToEarnings.mean]);

  const handleChangeSeries = useCallback(
    (newValue: string) => {
      popover.onClose();
      const seriesKeyToField: Record<string, PeerDataKey> = {
        'P/E Ratio': 'priceToEarnings',
        'P/B Ratio': 'priceToBook',
        'P/Sales Ratio': 'priceToSales',
        'P/FCF Ratio': 'priceToFreeCashFlows',
        'PEG Ratio': 'priceEarningsToGrowth',
      };
      const newField = seriesKeyToField[newValue];
      setCurrentSeriesKey(newValue);
      setCurrentSeriesField(newField);
      const foundPeer = peersData[newField].peers.find((peer: Peer) => peer.ticker === ticker);
      setCurrentSeriesValue(foundPeer ? foundPeer[newField] : undefined);
      setBarsColors(
        peersData[newField].peers.map((peer: Peer) =>
          peer.ticker === ticker ? primaryMain : primaryLight
        )
      );
      setCurrentMean(peersData[newField].mean);
    },
    [popover, ticker, peersData, primaryMain, primaryLight]
  );

  // Prepare data for the chart
  const data: ChartData[] = peersData[currentSeriesField].peers.map((peer: Peer) => ({
    ticker: peer.ticker,
    value: peer[currentSeriesField],
    companyName: peer.companyName,
  }));

  // Custom tooltip for Recharts
  const CustomTooltip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
    if (active && payload && payload.length) {
      const peerData = payload[0].payload as ChartData;
      return (
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            border: `1px solid ${theme.palette.grey[400]}`,
            p: 1,
          }}
        >
          <Typography variant="caption">{peerData.companyName}</Typography>
          <Typography variant="body2">
            {currentSeriesKey}: {peerData.value}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  if (!peersData.priceToEarnings.peers.length) {
    return (
      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" sx={{ color: 'text.primary' }}>
          Peers Comparison
        </Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          No peers data available
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Box>
        <Card>
          <CardHeader
            title="vs Peers"
            subheader={
              <Typography variant="body2" component="span" color="text.secondary">
                {currentSeriesKey}: <b>{currentSeriesValue}</b>
              </Typography>
            }
            action={
              <ButtonBase
                onClick={popover.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {currentSeriesKey}
                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ ml: 0.5 }}
                />
              </ButtonBase>
            }
          />
          <CardContent>

          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data} margin={{ top: 20 }}>
              <CartesianGrid vertical={false} strokeDasharray="3 3" opacity={0.5} />
              <XAxis
                dataKey="ticker"
                tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                axisLine={false}
                tickLine={false}
              />
              <YAxis
                tick={{ fontSize: 12, fill: theme.palette.text.secondary }}
                axisLine={{ stroke: 'transparent' }}
                tickLine={{ stroke: 'transparent' }}
              />
              <Tooltip content={<CustomTooltip />} />

              <Bar dataKey="value" radius={[5, 5, 0, 0]}>
                <LabelList
                  dataKey="value"
                  position="top"
                  style={{ fontSize: '13px', fill: '#304758' }}
                />
                {data.map((entry: ChartData, index: number) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={entry.ticker === ticker ? primaryMain : primaryLight}
                  />
                ))}
              </Bar>

              <ReferenceLine y={currentMean} stroke={theme.palette.grey[500]} strokeWidth={2} strokeDasharray="3 3">
                <Label
                  value={`Avg: ${currentMean}`}
                  position="insideBottomRight"
                  style={{
                    fill: theme.palette.grey[700],
                    backgroundColor: 'black',
                    fontSize: '12px',
                    padding: '2px',
                  }}
                />
              </ReferenceLine>
            </BarChart>
          </ResponsiveContainer>
          </CardContent>
        </Card>
      </Box>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {['P/E Ratio', 'P/B Ratio', 'P/Sales Ratio', 'P/FCF Ratio', 'PEG Ratio'].map((key) => (
          <MenuItem
            key={key}
            selected={key === currentSeriesKey}
            onClick={() => handleChangeSeries(key)}
          >
            {key}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
