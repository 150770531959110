import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Link from "@mui/material/Link";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React from "react";
import {paths} from "../../routes/paths";

const styledList = {
  py: 0,
  '& .MuiListItem-root': {py: 0.2},
  '& .MuiTypography-body2': {fontSize: '0.6rem'}
};

export default function HowScoringWorks() {

  return (
      <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{m: 5}}>
        <Box sx={{maxWidth: "600px"}}>
          <Box sx={{color: "text.primary"}}>
            <Typography variant="h6" gutterBottom>
              How our scoring algorithm works?
            </Typography>
            <Typography variant="body2">
              Our algorithm is based on the principles of <strong>value investing</strong> and <strong>fundamental analysis</strong>.
              The algorithm tries to evaluate the attractiveness of a company based on various factors and by relying on
              fundamental metrics and ratios.
              It aims to find companies that are undervalued, have strong financials, are efficient, and have good growth potential.
            </Typography>

            <Box height={10}/>
            {/*  1  */}
            <Typography variant="h6" gutterBottom>
              1. Gather data
            </Typography>
            <Typography variant="body2">
              Everyday we gather the latest data for all companies traded on NYSE, NASDAQ, and AMEX
              exchanges, totaling approximately 7,000 companies (we are working on adding other exchanges).
            </Typography>

            <Box height={10}/>

            {/*  2  */}
            <Typography variant="h6" gutterBottom>
              2. Overall score
            </Typography>

            <Typography variant="body2">
              Each company is evaluated based on <strong>four key components</strong>. Each component is rated on a 1-5 scale (5 being the
              best).
              The overall score for the company is the average of these four individual scores. The key components are:

              <List sx={styledList}>
                <ListItem>Valuation (Price Attractiveness)</ListItem>
                <ListItem>Financial Strength</ListItem>
                <ListItem>Efficiency</ListItem>
                <ListItem>Future Growth Potential</ListItem>
              </List>
            </Typography>
            <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{width: "100%"}}>
              <Box
                component="img"
                alt="Company logo"
                src={`${process.env.PUBLIC_URL}/assets/images/informational/scoring_radar.png`}
                sx={{
                  width: '400px',
                  height: 'auto',
                }}
              />
            </Stack>
            <Typography variant="body2">On the image above the company has an almost perfect score of 4.6 for <strong>Valuation</strong> (indicates it might be undervalued)
              and a good score of 2.9 on <strong>Future Growth</strong>.</Typography>
            <Typography variant="body2">The mean of all these 4 scores forms the overall score of
              3.63.</Typography>

            <Box height={10}/>

            {/*  3  */}

            <Typography variant="h6" gutterBottom>
              3. Component scores
            </Typography>

            <Typography variant="body2">
              The score for each component (e.g. Valuation) is the average score of the ratios/metrics that comprise
              that component. The ratios
              used for each component are:
            </Typography>

            <Typography variant="body2"> {/* This is the new line */} </Typography>
            <Box height={10}/>
            <Typography variant="body2">
              <strong>Valuation (Price Attractiveness)</strong>
            </Typography>
            <Typography variant="body2">
              <List sx={styledList}>
                <ListItem>Price To Earnings</ListItem>
                <ListItem>Price To Book</ListItem>
                <ListItem>Price to Free Cashflows</ListItem>
                <ListItem>Enterprise Value to EBITDA</ListItem>
                <ListItem>Price to Earnings Growth (PEG)</ListItem>
              </List>
            </Typography>

            <Box height={10}/>
            <Typography variant="body2">
              <strong>Financial Strength</strong>
            </Typography>
            <Typography variant="body2">
              <List sx={styledList}>
                <ListItem>Current ratio</ListItem>
                <ListItem>Quick ratio</ListItem>
                <ListItem>Debt To Equity ratio</ListItem>
                <ListItem>Debt To Assets ratio</ListItem>
                <ListItem>Interest Coverage Ratio</ListItem>
                <ListItem>Piotroski F-Score</ListItem>
              </List>
            </Typography>

            <Box height={10}/>
            <Typography variant="body2">
              <strong>Efficiency</strong>
            </Typography>
            <Typography variant="body2">
              <List sx={styledList}>
                <ListItem>Return On Capital Invested (ROCE)</ListItem>
                <ListItem>Return On Equity (ROE)</ListItem>
                <ListItem>Return On Assets (ROA)</ListItem>
                <ListItem>Gross Profit Margin</ListItem>
                <ListItem>Net Profit Margin</ListItem>
                <ListItem>Asset Turnover Ratio</ListItem>
              </List>
            </Typography>

            <Box height={10}/>
            <Typography variant="body2">
              <strong>Future Growth Potential</strong>
            </Typography>
            <Typography variant="body2" gutterBottom>
              <List sx={styledList}>
                <ListItem>Expected Revenue Growth (1 year)</ListItem>
                <ListItem>Expected EBITDA Growth (1 year)</ListItem>
                <ListItem>Expected EPS Growth (1 year)</ListItem>
                <ListItem>Past Revenue Growth (5 years)</ListItem>
                <ListItem>Past EBIDTA Growth (5 years)</ListItem>
                <ListItem>Past EPS Growth (5 years)</ListItem>
                <ListItem>Revenue Growth Consistency (5 years)</ListItem>
                <ListItem>EBITDA Growth Consistency (5 years)</ListItem>
                <ListItem>EPS Growth Consistency (5 years)</ListItem>
              </List>
            </Typography>
            <Typography variant="body2" gutterBottom>
              If a company has the following scores for each ratio:
            </Typography>
            <Typography variant="body2" gutterBottom>
              <List sx={styledList}>
                <ListItem>Price To Earnings: 4</ListItem>
                <ListItem>Price To Book: 3</ListItem>
                <ListItem>Price to Free Cashflows: 5</ListItem>
                <ListItem>Enterprise Value to EBITDA: 4</ListItem>
                <ListItem>Price to Earnings Growth (PEG): 3</ListItem>
              </List>
            </Typography>
            <Typography variant="body2" gutterBottom>
              The score for <strong>Valuation</strong> will be the average of these scores: (4 + 3 + 5 + 4 + 3) / 5
              = <strong>3.8</strong>
            </Typography>


            <Typography variant="subtitle1"> Range Scoring </Typography>
            <Typography variant="body2" gutterBottom>
              A score to each ratio is assigned based on what range it falls into. For example, a Price To Earnings
              ratio of 10 or lower will get a max score of 5, if P/E is between 10 and 15 it will get a score of 4, and
              so on.
              Similar for other ratios like Price To Book, Current Ratio, and so on.
            </Typography>
            <Typography variant="subtitle1">Industry matters</Typography>
            <Typography variant="body2" gutterBottom>
              The scoring is sector-specific. Each sector has its own scoring range for each ratio.
              For example, a Price To Book ratio of 1.5 or lower will get a max score of 5 for a company in the Basic
              Materials sector, while a company in the Technology
              sector will get a max score of 5 if P/B is 3 or lower.
            </Typography>
            <Typography variant="body2" gutterBottom>
              This is because different sectors have varying typical ratios based on the nature of their businesses.
            </Typography>
            <Typography variant="subtitle1">Growth (a special case)</Typography>
            <Typography variant="body2" gutterBottom>
              When scoring growth, the past and expected (future) growth rates are considered.
              For example a company that has grown its revenue by 20% per year for the past 5 years will get a higher
              score
              than a company that has grown its revenue by 10% per year. Similar for expected growth rates.
            </Typography>
            <Typography variant="subtitle2">Consistency</Typography>
            <Typography variant="body2" gutterBottom>
              Apart from the growth rates, the growth consistency is also considered. A company that has grown its
              revenue consistently by 10% per year for the past 5 years will get a higher score than a company that has
              grown its revenue by 10% per year but with fluctuations.
            </Typography>
            <Stack direction="column" spacing={2} alignItems="center" justifyContent="center" sx={{width: "100%"}}>
              <Box
                component="img"
                alt="Company logo"
                src={`${process.env.PUBLIC_URL}/assets/images/informational/growth_consistency.png`}
                sx={{
                  width: '350px',
                  height: 'auto',
                }}
              />
            </Stack>
            <Box height={10}/>
            <Typography variant="body2">On the image above is the growth scoring of AAPL. It grew it{`'`}s revenue all
              past 4 out of 5 years and it will get a score of 75% (equivalent to 4) for revenue growth
              consistency.</Typography>

          </Box>
          <Box sx={{color: "text.primary"}}>
            <Box height={10}/>
            <Typography variant="h6" gutterBottom>
              Where to next?
            </Typography>
            <Typography variant="body2" gutterBottom>
              View the top scoring companies according to this algorithm here: <Link href={paths.collections.topStocks}>top scored companies</Link>
            </Typography>
            <Typography variant="body2" gutterBottom>
              Screen companies based on their scores here: <Link href={paths.main.screener}>screener</Link>
            </Typography>

          </Box>

          <Divider sx={{my: 2}}/>
          <Box sx={{fontSize: 14}}>If you have any feedback, questions, or suggestions on this algorithm we would love to
            hear
            them!</Box>
          <Box sx={{fontSize: 13}}>Drop me a line at <Link
            sx={{cursor: "pointer"}}>
            contact@valuehunter.net</Link>
          </Box>
        </Box>
      </Stack>
  );
}
